import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/storage'



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyBa1w31rSwWEhz8SYXb1dEHaj99_RnCBo8",
  authDomain: "sajili-df707.firebaseapp.com",
  projectId: "sajili-df707",
  storageBucket: "sajili-df707.appspot.com",
  messagingSenderId: "100516656034",
  appId: "1:100516656034:web:3d1ca2354bad29914c38fb",
  measurementId: "G-MH7Z4TSMNE"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const storage = firebase.storage()

firebase.firestore().settings({ timestampsInSnapshots: true });

export { storage, firebase as default};