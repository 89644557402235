import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteQuestion } from '../../../store/actions/questionActions'

class QuestionList extends Component {

    handleQuestionDelete = (e) => {
        this.props.deleteQuestion(e)
    }
    render() {

        const locArr = this.props.location_items
        const location_items = locArr ? Object?.values(locArr) : null
        const id = this.props.location_id
        
    
       
        if(location_items) {
  
            return (
                <div className='visitor-list z-depth-2 scroll'>
                <div className=''>
                <h5>Secondary Questions </h5>
                    <table className="bordered stripped highlight">
                        <thead>
                            <tr>
                                <th>Label</th>
                                <th>Placeholder</th>
                                <th>Required</th>
                                <th>Type</th>
                                <th></th>
                            </tr>
                        </thead>
                    <tbody>
                        {location_items && location_items
                            .filter(location_item => location_item.location === id)
                            .map(
                                form_item => {
                                    return (
                                        <tr key={form_item.id}>
                                            <td> 
                                                { form_item.label ? form_item.label : '-' }
                                            </td>
                                            <td>
                                                { form_item.place_holder ? form_item.place_holder : '-' }
                                            </td>
                                            <td>
                                                { form_item.required ? form_item.required.toString() : 'false'}
                                            </td>
                                            <td>
                                                { form_item.type ? form_item.type :  '-'}
                                            </td>
                                            <td>
                                                <div className="question-delete" onClick={() => this.handleQuestionDelete(form_item)}>
                                                    Delete
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            )}
                    </tbody>
                    </table>
                </div>
                </div>
            )
        }else{
            return(
                <div className="center">
                    Loading...
                </div>
            )
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteQuestion: (que) => dispatch(deleteQuestion(que))
    }
}



export default connect(null, mapDispatchToProps)(QuestionList)
