import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createReferral } from '../../../store/actions/referActions';
import './referal.css'

class ReferUser extends Component {

    state = {
        email : '',
        company_one : '',
        email_two : '',
        company_two : ''
    }


    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { user_roles, profile } = this.props;
        const company = user_roles && user_roles.find(comp => comp.level === 'company');

        this.setState({
            referCompany : company?.entity_id,
            referred_by : profile.first_name + ' ' + profile.last_name
        // }, () => {console.log(this.state)})
        }, () => {this.props.createReferral(this.state)})
        
    }
    render() {

        const { user_roles } = this.props;
        const company = user_roles && user_roles.find(comp => comp.level === 'company');
        console.log(company?.entity_id)
        return (
            <div className='refer-user-parent bg-1'>
                <div className="l-side">
                   <h3 className="landing-title">
                       Refer 2 users to enjoy the free service.
                   </h3>
                   <h4 className="landing-subtitle">
                   In order to continue enjoying unlimited visitor registration, refer
                   two users to enjoy our services. These users must set up within 2 weeks to avoid your free account’s deactivation. 
                   </h4>
               </div>
               <div className="r-side ovfl-aut">

                   <form onSubmit={this.handleSubmit} className="refer-user-form">
                       
                   <div className="input-field mb-40">
                        <input 
                        placeholder='Type email here' 
                        id="email" 
                        type="email"
                        
                        required 
                        className="validate f-inpt" 
                        onChange={this.handleChange}/>

                        <label 
                        className="active" 
                        htmlFor="email">Email</label>
                    </div>
                    
                   <div className="input-field mb-40">
                        <input 
                        placeholder='Type name here' 
                        id="company_one" 
                        type="text"
                        
                        required 
                        className="validate f-inpt" 
                        onChange={this.handleChange}/>

                        <label 
                        className="active" 
                        htmlFor="company_one">Company/Individual Name</label>
                    </div>
                    <hr />
                    <div className="input-field mbtm mt-40">
                        <input 
                        placeholder='Type email here' 
                        id="email_two" 
                        type="email"
                        
                        required 
                        className="validate f-inpt" 
                        onChange={this.handleChange}/>

                        <label 
                        className="active" 
                        htmlFor="email_two">Email</label>
                    </div>
                    
                   <div className="input-field mt-40">
                        <input 
                        placeholder='Type name here' 
                        id="company_two" 
                        type="text"
                        
                        required 
                        className="validate f-inpt" 
                        onChange={this.handleChange}/>

                        <label 
                        className="active" 
                        htmlFor="company_two">Company/Individual Name</label>
                    </div>
                    <div className="rf-btn">
                        <button className="filled-btn refer-btn">
                            Submit
                        </button>
                    </div>
                   </form>
               </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    
    return {
        auth: state.firebase.auth, 
        profile : state.firebase.profile,
        user_roles : state.firestore.ordered.user_roles,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createReferral : (refer) => dispatch(createReferral(refer))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ReferUser)
