// import { motion } from 'framer-motion';

// const svgVariants = {
//     hidden : { rotate: -180 },
//     visible : {
//         rotate: 0,
//         transition : { duration : 1}
//     }
// }

// const pathVariants = {
//     hidden : {
//         opacity : 0,
//         pathLength : 0
//     },
//     visible : {
//         opacity : 1,
//         pathLength : 1,
//         transition : {
//             duration : 2,
//             ease: "easeInOut"
//         }
//     }
// }

import { PuffLoader, PulseLoader } from "react-spinners";

const ContactSuccess = () =>{
    return (
        <div className="contact-success">
            <PuffLoader size='200' />
            <h4>Loading
                <PulseLoader size='5' color='blue' />
            </h4>
        </div>
    )
}

export default ContactSuccess;