

//SIGNING IN 

export const signIn = (credentials) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {
            dispatch({ type: 'LOGIN_SUCCESS' });
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR', err });
        })
    }
} 

//SIGNING OUT 

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({type: 'SIGNOUT_SUCCESS'});
        });
    }
}


//SIGNING UP 

export const signUp = (newUser) => {
    return (dispatch, getState, { getFirebase, getFirestore}) =>{
        const firebase = getFirebase();
        const firestore = getFirestore();

        var myDate = new Date();


        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then((resp) => {
            return ([
                firestore.collection('users').doc(resp.user.uid).set({

                    first_name: newUser.first_name,
                    last_name: newUser.last_name,
                    email: newUser.email,
                    phone_number: newUser.phone_number,
                    registered_on : new Date(),
                    // referred_by: newUser.referred_by,
    
                    company_name: newUser.company_name,
                    country_code: newUser.country_code,
                    initials: newUser.first_name[0] + newUser.last_name[0]
    
                }),

                resp.user.sendEmailVerification(),

                firestore.collection('company').add({
                    company_name : newUser.company_name, 
                    created_on : new Date(),
                    status : 'trial',
                    number_of_locations : 0
                }).then((comp) => {
                    return ([
                        firestore.collection('company').doc(comp.id).update({
                            company_id : comp.id
                        }),

                        firestore.collection('referral_reminder').add({
                            company_id : comp.id,
                            contact_email : newUser.email,
                            reminder_date : myDate.setMonth(myDate.getMonth() + 2)
                        }),

                        firestore.collection('user_roles').add({
                            user_id : resp.user.uid,
                            entity_id : comp.id,
                            role_id : '7SZm4a4re072G5XN7FhZ',
                            level : 'company',
                            created_on : new Date(),
                        })
                    ])
                })
            ])
        }).then(() => {
            dispatch({ type: 'SIGNUP_SUCCESS' })
        }).catch((err) => {
            dispatch({type: 'SIGNUP_ERROR', err})
        })
    }
}



//RESET PASSWORD

export const resetPassword = (userEmail) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();

        firebase.auth().sendPasswordResetEmail(
            userEmail.email
        ).then(() => {
            dispatch({ type : 'PASSWORD_RESET_SUCCESS'});
        }).catch((err) => {
            dispatch({ type: 'PASSWORD_RESET_ERROR', err});
        })
    }
}

