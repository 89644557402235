import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { createCheckout } from '../../../store/actions/checkinActions'
import QRScanError from '../pages/QRScanError'

class CheckOut extends Component {

    state = {
        id_number : '',
        location : '',
        phone_number : localStorage.getItem('phone_number') || ''
    }

    componentDidMount(){
        this.setState({
            location : this.props.location.location_into
        })
    }

    handleChange = (e) => {
        localStorage.setItem([e.target.id], e.target.value);
        this.setState({
            [e.target.id] : e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault() ;
        this.props.createCheckout(this.state)
    }


    render() {

        const location_checkin = this.state.location

        const { checkOutStatus } = this.props

        if(checkOutStatus === 'success') return <Redirect to={'/mobile/checkout/success/' + location_checkin} />
        if(this.state.location) {
            return (
                <div className='checkin-parent'>
                    <form onSubmit={this.handleSubmit} className='checkout-form'>
                    <div className="input-field mb-40">
                            <input 
                            placeholder='Phone Number (Telefon)'
                            type="tel" 
                            minLength="8"
                            id="phone_number"
                            value = {localStorage.getItem('phone_number')}
                            required
                            className='validate f-inpt'
                            onChange={this.handleChange}
                            />
     
                            <label htmlFor="phone_number" className="active fnt-16">
                                Phone Number
                            </label>
                        </div>
    
                        <div className="input-field center" >
                            <button className="outline-btn z-depth-1" id='mobile-btn-width-full'>
                                Check Out
                            </button>
                        </div>
                    </form>
                </div>
            )
        }else {
            return(
                <div className="center">
                    <QRScanError checkType = 'Checkout Error' inOrOut = 'out'/>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        checkOutStatus : state.checkIn.checkOutStatus,
        form_items : state.firestore.ordered.form_items,
        location_items : state.firestore.ordered.location_items
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createCheckout : (checkout) => dispatch(createCheckout(checkout))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (CheckOut)