import ContactForm from "./ContactForm";
import ContactContent from "./ContactContent";
import './contact.css';
import { motion } from 'framer-motion'

const ContactPage = () =>{

    const containerVariants = { 
        hidden : {
            x: '100vw'
        },
        visible : {
            x: '0vw',
            transition : { ease: 'easeInOut', duration: 1}
        },
        exit: {
            x: '-100vw',
            transition: {ease: 'easeInOut', duration: 1}
        }
    }

    return (
        <motion.div
        initial='hidden'
        animate='visible'
        exit='exit'
        variants={containerVariants} 
        className="contact-page bg-1">
            <div className="left-side split-screen">
                <ContactContent />
            </div>
            <div className="right-side split-screens">
                <ContactForm />
            </div>
        </motion.div>
    )
}

export default ContactPage;