export const createCheckin = (checkin) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        firestore.collection('visitors').add({
           ...checkin,
           time_in: new Date(),
           time_out : ''
        }).then(() => {
            dispatch({ type: 'CHECK_IN_VISITOR', checkin });
        }).catch((err) => {
            dispatch({ type: 'CHECK_IN_VISITOR_ERROR', err })
        })

    }
}

export const createCheckout = (checkout) => {
    return (dispatch, getState, { getFirebase, getFirestore }) =>{
        const firestore = getFirestore();
        const location = checkout.location;
        
        const phone_number = checkout.phone_number;

        const checkins = getState().firestore.ordered.visitors;
        const checkin_id = checkins && checkins
        .find(checkin => checkin.phone_number === phone_number && checkin.time_out === '' && checkin.location === location)
        const checkinId = checkin_id.id

        

        firestore.collection('visitors').doc(checkinId).update({
            time_out: new Date(),
            // added : checkinId,
            

        }).then(() => {
            dispatch({ type: 'CHECKOUT_VISITOR', checkout });
        }).catch((err) => {
            dispatch({ type: 'CHECKOUT_VISITOR_ERROR', err })
        })
    }
}
