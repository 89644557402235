
import React from 'react';
import { motion } from 'framer-motion';

const TermsAndConditions = () => {

    const containerVariants = { 
        hidden : {
            x: '100vw'
        },
        visible : {
            x: '0vw',
            transition : { ease: 'easeInOut', duration: 1}
        },
        exit: {
            x: '-100vw',
            transition: {ease: 'easeInOut', duration: 1}
        }
    }
    return (
        <motion.div 
        initial='hidden'
        animate='visible'
        exit='exit'
        variants={containerVariants} 
        className='bg-2 ovfl-aut'>
            <div className="landing-content z-depth-2">
                <div className="content-banner ingia-blue-bg mb-50">
                    TERMS AND CONDITIONS
                </div>
                <h5>Welcome to Ingia</h5>
                <p>
                    These terms and conditions outline the rules and regulations for the use of Acuvera Systems Ltd. 's Website, located at www.ingia.co.
                </p>
                <p>
                    By accessing this website we assume you accept these terms and conditions. Do not continue to use Ingia.co if you do not agree to take all of the terms and conditions stated on this page.
                </p>
                <p>
                    The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Kenya. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
                </p>
                <h5>Cookies</h5>
                <p>
                    We employ the use of cookies. By accessing Ingia.co, you agreed to use cookies in agreement with the Acuvera Systems Ltd. 's Privacy Policy.
                </p>
                <p>
                    Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
                </p>
                <h5>License</h5>
                <p>
                    Unless otherwise stated, Acuvera Systems Ltd. and/or its licensors own the intellectual property rights for all material on Ingia.co. All intellectual property rights are reserved. You may access this from Ingia.co for your own personal use subjected to restrictions set in these terms and conditions.
                </p>
                <p>You must not:</p>
                <ol className='ml-20'>
                    <li>Republish material from Ingia.co</li>
                    <li>Sell, rent or sub-license material from Ingia.co</li>
                    <li>Reproduce, duplicate or copy material from Ingia.co</li>
                    <li>Redistribute content from Ingia.co</li>
                </ol>
                <p>
                    This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Terms And Conditions Generator and the Privacy Policy Generator.
                    This website collects basic data on each user for the purpose of security or front desk check in of buildings or offices accessed by the public.  This information will only be shared by Acuvera Systems Limited to the administrator of the company, government office or other party being visited.   Your data is held temporarily and stored in the form of cookies so that each time you access the web form, your basic data is auto completed for convenience.  Under this arrangement none of your personal data is stored by Acuvera Systems Limited and no data is mined off your mobile phone, tablet or laptop.  
                </p>
                <p>
                    Acuvera Systems Ltd. reserves the right to monitor and to remove any of your data which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.
                </p>
                <h5>
                    You warrant and represent that:
                </h5>
                <ol className='ml-20'>
                <li>
                    You are entitled to post your data on our website and have all necessary licenses and consents to do so;
                </li>
                <li>
                    The data you provide does not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;
                </li>
                <li>
                    The data you provide does not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy
                </li>
                <li>
                    The data will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.                 
                </li>                
                </ol>
                <h5>Hyperlinking to our Content</h5>
                <p>
                    No use of Acuvera Systems Ltd. 's logo or other artwork will be allowed for linking absent a trademark license agreement.
                </p>
                <h5>iFrames</h5>
                <p>
                    Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website
                </p>
                <h5>Content Liability</h5>
                <p>
                    We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
                </p>
                <p>Your Privacy <br/>
                Please read <a href="/landing/privacypolicy">Privacy Policy</a> </p>
                <h5>Reservation of Rights</h5>
                <p>
                    We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
                </p>
                <h5>Disclaimer</h5>
                <p>
                    To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
                </p>
                <ol className='ml-20'>
                    <li>limit or exclude our or your liability for death or personal injury;</li>
                    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>limit any of our or your liabilities in any way that is not permitted under applicable  law; or</li>
                    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                </ol>
                <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>
                <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
            </div>
            
        </motion.div>
    )
}

export default TermsAndConditions
