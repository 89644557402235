import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

// import * as geolib from 'geolib';
import LoadingPage from '../../LoadingPage';



class InOrOut extends Component {

    state = {
        // currentLatitude : '',
        // currentLongitude : ''
    }

    componentDidMount() {
        // if(navigator.geolocation){
        //     navigator.geolocation.getCurrentPosition((position) => {
        //         console.log("Latitude is : ", position.coords.latitude);
        //         console.log("Longitude us : ", position.coords.longitude);
        //         this.setState({
        //             currentLatitude: position.coords.latitude, 
        //             currentLongitude: position.coords.longitude
        //         })
        //     });
        // }else{
        //     alert('Geolocation is not supported by the browser')
        // }
    }


    render() {
        const { checkin_location, location_id } = this.props;
        const location_qr = checkin_location?.location_qr;

        // const geoFence = geolib.isPointWithinRadius(
        //     { latitude: this.state.currentLatitude, longitude: this.state.currentLongitude },
        //     { latitude: checkin_location?.latitude, longitude: checkin_location?.longitude },200
        // );

        // console.log(geoFence);

        if(location_id){
            // if(geoFence === true){
                return (
                    <div className='checkin-type'>
                        <div className="checkin-type-actions">
                            <h5>{ checkin_location.location_name } </h5>
            
                            <Link to={{ pathname : '/mobile/checkin/' + location_qr, location_into : location_id?.toString()}}>
                                <button className="filled-btn mobile-btn-width">
                                    Check In  
                                </button>
                            </Link>
                            <h4>OR</h4>
                            <Link to={{ pathname : '/mobile/checkout/' + location_qr, location_into : location_id?.toString()}}>
                                <button className="outline-btn mobile-btn-width">
                                    Check Out
                                </button>
                            </Link>
                        </div>
            
                        <CookieConsent
                        buttonText="Agree"
                        style={{background : '#f2f2f2', color : '#1741ff'}}
                        buttonStyle={{ background: '#1741ff', color: '#fff', borderRadius: '50px', padding: '10px', width: '100px'}}
                        >In order to remember your details for future checkins, Ingia uses cookies.</CookieConsent>
                    </div>
                )
            // }else{
            //     return (
            //         <div className="loc-err">
            //             <div className="out-of-location z-depth-2">
            //                 <h5>Error checking in</h5>
            //                 <h6>You have to be within 200 meters of <b>{checkin_location.location_name}</b> front desk to check in.</h6>
            //                 <h6>Please move to the location you are trying to sign into and scan the QR code again.</h6>
            //                 <h6>If requested to allow location services, please click allow in order to use the service.</h6>
            //             </div>
            //         </div>
            //     )
            // }
        }else{
            return (
                <LoadingPage message='Loading data' />
            )
        }

    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const locations = state.firestore.ordered.locations;
    const locationsData = state.firestore.data.locations;

    const location_id = locations ? locations.filter(location => location.location_qr === id)
    .map(location => {
        return location.id
    }) : null
    const location = locationsData ? locationsData[location_id] : null

    return {
        checkin_location : location,
        location_id : location_id, 
    }
}


export default connect(mapStateToProps) (InOrOut)
