
import React from 'react'
import { Link, NavLink } from 'react-router-dom'

const MobileSignedInLinks = () => {
    return (
        <ul className='mobile-links'>
            <li>
                <Link to='/' className='mobile-profile-initials'>
                    <h5>P.D</h5>
                </Link>
            </li>
            <li>
                <NavLink activeClassName='active-link' to='/contact' className='black-text'>
                    Notifications
                </NavLink>                
            </li>
            <li>
                <NavLink activeClassName='active-link' to='/fullpage'className='black-text'>
                    Log Out
                </NavLink>                
            </li>
        </ul>
    )
}

export default MobileSignedInLinks
