import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';


const checkboxes = [
    { id: "daily", text: "Daily" },
    { id: "weekly", text: "Weekly" },
    { id: "monthly", text: "Monthly" }
  ];

class ReportFrequency extends Component {
    state = {
        selectedCheckboxes: []
      };

    handleChange = (e) => {
        this.setState({
            dailyReport : !this.state.dailyReport
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
    }

    onChange = id => {
        const selectedCheckboxes = this.state.selectedCheckboxes;
    
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);
    
        // Index > -1 means that the item exists and that the checkbox is checked
        // and in that case we want to remove it from the array and uncheck it
        if (findIdx > -1) {
          selectedCheckboxes.splice(findIdx, 1);
        } else {
          selectedCheckboxes.push(id);
        }
    
        this.setState({
          selectedCheckboxes: selectedCheckboxes
        });
      };
    

    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to='/app/signin' />
        if (!auth.emailVerified) return <Redirect to='/app/verifyemail' />

        return (
            <div className='bg-2'>
                <form onSubmit={this.handleSubmit} className="rep-freq">
                    <h3>Set reports frequency</h3>
                    <h5>Reports sent to registered email</h5>
                    <div className="reports-checkboxes z-depth-1">
                    {checkboxes.map(checkbox => (
                        <label key={checkbox.id}>
                            <p>
                                <label>
                                    <input 
                                    type="checkbox" 
                                    id='daily-reports' 
                                    className="filled-in" 
                                    onChange={() => this.onChange(checkbox.id)}
                                    selected={this.state.selectedCheckboxes.includes(checkbox.id)}
                                    />
                                    <span>{checkbox.text}</span>
                                </label>
                            </p>
                            
                
                        </label>
                    ))} 
 
                    </div>
                    <div className="input-field col s6">
                        <button className="btn z-depth-2 right filled-btn">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth : state.firebase.auth
    }
}

export default connect(mapStateToProps) (ReportFrequency)
