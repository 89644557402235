const initState = {
    locations : [
        {id: '0123', location_name: 'Here', location_description: 'Here Description', location_country: 'Kenya', physical_address: '123, Street'},
        {id: '4567', location_name: 'Some', location_description: 'Some Description', location_country: 'Kenya', physical_address: '123, Street'},
        {id: '8910', location_name: 'Where', location_description: 'Where Description', location_country: 'Kenya', physical_address: '123, Street'},
        {id: '1112', location_name: 'There', location_description: 'There Description', location_country: 'Kenya', physical_address: '123, Street'}
    ],
    create_location_status : 'init'
}

const locationReducer = (state = initState, action) => {
    switch(action.type) {
        case 'CREATE_LOCATION': 
            console.log('created location', action.location);
            return {
                state,
                create_location_status: 'created'
            }

        case 'CREATE_LOCATION_ERROR': 
            console.log('create location error', action.err);
            return state;

        case 'LOCATION_DELETION_SUCCESS':
            console.log('location successfully deleted', action.location);
            return {
                state,
            }
        
        case 'LOCATION_DELETION_ERROR':
            console.log('location deletion error', action.err)
            return state;

        case 'LOCATION_SUSPEND_SUCCESS': 
            console.log('location suspension success', action.location);
            return {
                state,
            }
            
        case 'LOCATION_SUSPEND_ERROR': 
            console.log('location suspension error', action.err)
            return state

        case 'LOCATION_ACTIVATE_SUCCESS': 
            console.log('location activate success', action.location);
            return {
                state,
            }

        case 'LOCATION_ACTIVATE_ERROR': 
            console.log('location activate error', action.err)
            return state

        default:
            return state
    }
}

export default locationReducer;
