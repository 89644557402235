import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSpring, useSpringRef, animated } from 'react-spring'
import './website.css';
import imageOne from '../../assets/images/Landing/one.png';
import imageTwo from '../../assets/images/Landing/two.png';
import imageThree from '../../assets/images/Landing/three.png';
import imageFour from '../../assets/images/Landing/four.png';

import { motion } from 'framer-motion';





const HomePage = () =>  {

    const [pageNumber, setPage] = useState(0);
    const landingImage = [imageOne, imageTwo, imageThree, imageFour];
    const content = [
        {id: '1', title: 'Contactless check-in software', content: 'Service remains free with 2 successful referrals or more than 10 locations.'},
        {id: '2', title: 'Free QR code based solution for rapid electronic visitor tracking', content: 'Electronic logs are saved for future reference and ease of management'},
        {id: '3', title: 'Scan the QR code with your camera. Complete your personal details on the web form.', content: 'Print and display QR codes for visitors to scan using their smartphone camera.'},
        {id: '4', title: 'Register to set up your locations and configure how the data is managed.', content: 'No app download required. An internet enabled smartphone is required for this system to work.'}
    ];

    const transRef = useSpringRef();
    const transOne = useSpringRef();
    const transTwo = useSpringRef();
    const transThree = useSpringRef();

    const styles = useSpring({
        ref: transRef,
        to: [
            {x: 0}
          ],
        from : {x: 200},
        config: {duration: 1000}
    });

    const stylesOne = useSpring({
        ref: transOne,
        to: [{x: 0, opacity : 1}],
        from: {x: 200, opacity: 0},
        delay: 200,
        config: {duration: 1000}
    });

    const stylesTwo = useSpring({
        ref: transTwo,
        to: [
            {y: 0, opacity: 1}
        ],
        from : {y: 100, opacity: 0},
        config: {duration: 1000}
    });

    const stylesThree = useSpring({
        ref: transThree,
        to: [{opacity : 1}],
        from: {opacity: 0},
        config: {duration: 1500}
    });

    useEffect(() => {
        transRef.start({
            to: [
                {x: 0}
              ],
            from : {x: 200},
            config: {duration: 1000}
        });

        transOne.start({
            to: [{x: 0, opacity : 1}],
            from: {x: 300, opacity: 0},
            config: {duration: 1500}
        });

        transTwo.start({
            to: [
                {y: 0, opacity: 1}
            ],
            from : {y: 100, opacity: 0},
            config: {duration: 1000}
        });

        transThree.start({
            to: [{opacity : 1}],
            from: {opacity: 0},
            config: {duration: 1500}
        })
    })
    
    const handleNext = () => {
        if(pageNumber < 3) {
            setPage(pageNumber + 1);
        } else {
            setPage(0)
        }
    }

    const containerVariants = { 
        hidden : {
            x: "100vw"
        },
        visible : {
            x: '0vw',
            transition : { ease: 'easeInOut', duration: 1}
        },
        exit: {
            x: '-100vw',
            transition: {ease: 'easeInOut', duration : 1}
        }
    }

    return (
        <motion.div 
        className='bg-1' 
        variants={containerVariants}
        initial="hidden"
        animate='visible'
        exit='exit'>
            <div className="left-side split-screen">
                <div className="left-side-content">

                    <animated.div className="header-one" style={styles}>
                        <h3>{content[pageNumber].title}</h3> 
                    </animated.div>
                    <animated.div className="header-two" style={stylesOne}>
                        <h4>{ content[pageNumber].content}</h4>
                    </animated.div>
                    <animated.div className="link-area" style={stylesThree}>
                        <div>
                            {
                                pageNumber !== 3 ?
                                <button className="outline-btn icon-btn icon-right" onClick={handleNext}>
                                    Next
                                    <i className="material-icons">arrow_forward</i>
                                </button>    : 
                                <Link to='/app/register'>
                                    <button className="outline-btn icon-btn icon-right">
                                        Register
                                        <i className="material-icons">arrow_forward</i>
                                    </button>                                   
                                </Link>

                            }
                        </div>
                        <div>
                        <ul className="links">
                            <li className="landing-links">
                                <Link to='/privacy'>Privacy Policy</Link>
                            </li>
                            <li className="landing-links">
                                <Link to='/termsandconditions'>Terms & Conditions</Link>
                            </li>
                            <li className="landing-links">
                                <Link to='/registrationterms'>Registration Conditions</Link>
                            </li>
                            <li className="landing-links">
                                <Link to='/faq'>FAQ</Link>
                            </li>
                        </ul>                              
                        </div>                
                    </animated.div>
                </div>
            </div>

            <animated.div className="right-side split-screen" style={stylesTwo}>
                <div className="landing-image"
                    style={{
                        backgroundImage: "url(" + landingImage[pageNumber] + ")",
                    }}>
                        
                </div>
            </animated.div>

        </motion.div>
    )
}

export default HomePage
