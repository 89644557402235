import React from 'react';
import './pages.css';
import suspended from '../../../assets/images/art/suspended.png'
import { Link } from 'react-router-dom';

const AccountSuspended = () => {
    return (
        <div className='account-suspended ovfl-aut'>
            <div className="l-side">
                <img src={suspended} alt=""/>
            </div>
            <div className="r-side">
                <div className="r-side-one">

                
                <h3 className='mb-80'>Account Suspended</h3>
                <h4 className='mb-80'>Your account has been suspended</h4>
                <h5>To reactivate your account, either refer two users or set up 10 or more active locations.</h5>
                <div className="suspended-buttons">
                    <Link to='/app/referusers'>
                        <button className="outline-btn">Refer users </button>
                    </Link>
                    <Link to='/app/createlocation'> 
                        <button className="outline-btn">Set up locations</button>
                    </Link>
                </div>
            </div>
            </div>
        </div>
    )
}

export default AccountSuspended
