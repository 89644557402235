import React from 'react'
import { Link } from 'react-router-dom'

const QuestionButtons = ({ location_id, location_qr }) => {
    return (
        <div className="location-buttons">
            <ul>
                <Link to={'/app/qrcode/' + location_qr}>
                    <li>Print QR Code</li>      
                </Link>
                {/* <Link to='/'>
                    <li>Generate Report</li>    
                </Link> */}
            </ul>
        </div>
    )
}

export default QuestionButtons
