
import React from 'react';
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';

const RegistrationTerms = () => {

    
    const containerVariants = { 
        hidden : {
            x: '100vw'
        },
        visible : {
            x: '0vw',
            transition : { ease: 'easeInOut', duration: 1}
        },
        exit: {
            x: '-100vw',
            transition: {ease: 'easeInOut', duration: 1}
        }
    }

    return (
        <motion.div 
        initial='hidden'
        animate='visible'
        exit='exit'
        variants={containerVariants} 
        className='bg-2 ovfl-aut'>
            <div className="landing-content z-depth-3">
            <div className="content-banner ingia-blue-bg mb-50">
                REGISTRATION TERMS
            </div>
                 <h5>Welcome to Ingia</h5>
                 <p>Ingia is a free service that gives you access to a variety of visitor checkin and employee tracking solutions. </p>
                <p>There are two options for registering and enjoying our free service. </p>
                <ol className="ml-20">
                    <li>Refer 2 users</li>
                    <li>Create more than 9 centers</li>
                </ol>
                <h5>1. Referral</h5>
                    <p>When setting up an account, you will be prompted to refer 2 users by entering their names and 
                        email address. These users will receive an invite from you with a link that they should use to set
                        up their accounts. If your referrals sign up within 2 weeks, you will continue enjoying our service. 
                        If the 2 week period lapses before they set up, your account will be temporarily suspended with 
                        an option to make new referrals. </p>
                <h5>2. Multiple Centers</h5>
                <p>
                    If your company has more than 9 centers for which you will be handling visitor checkins, you are
                    eligible for a free account. Register your company and set up your centers and you are ready to 
                    go. 
                </p>
                <h5>3. Security Company </h5>
                <p>Security companies can create free accounts with the condition that they set up at least ten 
                centers on the platform. </p>

                <Link to='/app/register' className='ml-20'>
                    <button className="filled-btn">
                        Register
                    </button>
                </Link>
            </div>
        </motion.div>
    )
}

export default RegistrationTerms
