const initState = {
    checkInStatus : null,
    checkOutStatus : null
}

const checkinReducer = (state = initState, action) => {

    switch (action.type){
        case 'CHECK_IN_VISITOR':
            console.log('Visitor Checked In', action.checkin);
            return {
                ...state,
                checkInStatus : 'success'
            }

        case 'CHECK_IN_VISITOR_ERROR':
            console.log('Visitor checkin error', action.err);
            return state;

        case 'CHECKOUT_VISITOR':
            console.log('Visitor Checked Out', action.checkout);
            return {
                ...state,
                checkOutStatus : 'success'
            }

        case 'CHECKOUT_VISITOR_ERROR':
            console.log('Visitor checkout error', action.err);
            return state;
        default: return state;
    }
}

export default checkinReducer; 