import React, { Component } from 'react';
import './playfield.css';

import dashboardimage from '../../assets/images/art/merge.png';
import { Link } from 'react-router-dom';

export class PlayField extends Component {

    componentDidMount() {
        const M = window.M;
        M.AutoInit();
    }

    handleDashLinks = () => {
        const dashLinks = document.getElementById('dashLinks');

        if(dashLinks.style.visibility !== 'visible'){
            dashLinks.style.visibility = 'visible';
            dashLinks.style.opacity = 1;
        }else{
            dashLinks.style.visibility = 'hidden';
            dashLinks.style.opacity = 0;
        }


    }


    render() {
        return (
            <div className = 'playfield-parent'>
                <div className="dash-links" id='dashLinks'>
                    <ul>
                        <li> 
                            <Link to='/privacy'>Privacy Policy</Link>
                        </li>
                        <li >
                            <Link to='/termsandconditions'>Terms & Conditions</Link>
                        </li>
                        <li >
                            <Link to='/registrationterms'>Registration Conditions</Link>
                        </li>
                        <li >
                            <Link to='/faq'>FAQ</Link>
                        </li>
                    </ul>  
                </div>
                <img src={dashboardimage} alt="dashboard" />
                <div className="launcher">
                    <i 
                    onClick={this.handleDashLinks}
                    className="material-icons  more-icons" 
                    // data-position="top"
                    // data-tooltip="I am a tooltip"
                    >more_vert</i>
                </div>
            </div>
        )
    }
}

export default PlayField
