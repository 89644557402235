import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import HomePage from '../website/HomePage';
import FAQ from '../website/FAQ';    
import PrivacyPolicy from '../website/PrivacyPolicy';
import RegistrationTerms from '../website/RegistrationTerms';
import TermsAndConditions from '../website/TermsAndConditions';

import { AnimatePresence } from 'framer-motion';
import ContactPage from '../website/contact/ContactPage';
import ContactSuccess from '../website/contact/ContactSuccess';
function LandingPages() {

    const location = useLocation();
    return (
        <div className='landing-page'>
            <AnimatePresence exitBeforeEnter>
                <Switch location = {location} key={location.key}>
                    <Route path='/faq' component={FAQ} />
                    <Route path='/privacy' component={PrivacyPolicy} />
                    <Route path='/registrationterms' component={RegistrationTerms} />
                    <Route path='/termsandconditions' component={TermsAndConditions} />
                    <Route path='/contactpage' component={ContactPage} />
                    <Route path='/suc' component={ContactSuccess} />
                    <Route exact path='/' component={HomePage} />
                </Switch>
            </AnimatePresence>
            {/* <HomePage />
            <FAQ /> */}
        </div>
    )
}

export default LandingPages
