
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { signOut } from '../../store/actions/authActions';

const SignedInLinks = (props) => {
    const profile = props.profile
    return (
        <div className='signed-in-links black-text'>
            <ul className="right hide-on-med-and-down">
                <li onClick={props.signOut}>
                    <NavLink activeClassName='active-link'  to='/' className='black-text'>
                        Log Out
                    </NavLink>                
                </li>

                <li>
                    <Link to='/app/userprofile' className='btn btn-floating white black-text'>
                        {profile.initials}
                    </Link>
                </li>
            </ul>
            
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () =>dispatch(signOut())
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect( props => [
        { collection : 'user_roles', where: [["user_id", "==", props.auth.uid]]}
    ])
)(SignedInLinks)
 
