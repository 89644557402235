const initState = {

}

const referReducer = (state = initState, action) => {
    switch(action.type) {
        case 'CREATE_REFER_SUCCESS': 
            console.log('referral created', action.ref);
            return {
                state, 
            }

        case 'CREATE_REFER_ERROR' : 
            console.log('creating referral error', action.err);
            return state;

        default: 
            return state
    }
}

export default referReducer;