import React from 'react';
import { connect } from 'react-redux';

const CheckoutSuccess = ({ checkin_location, location_id }) => {
    return (
        <div className="checkout-success center">
            <h4>Success!</h4>
            <i className="large material-icons success-checkout ingia-blue-txt">check</i>
            <h5>You have successfully checked out of</h5>
            <h3 className='mb-40'>{ checkin_location ? checkin_location.location_name : 'Loading...'}</h3>
            <h6>Show this to the guard before exit</h6>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;

    const locations = state.firestore.ordered.locations;
    const locationsData = state.firestore.data.locations;

    const location_id = locations ? locations.filter(location => location.location_qr === id)
    .map(location => {
        return location.id
    }) : null

    const location = locationsData ? locationsData[id] : null
    return {
        checkin_location : location,
        location_id : location_id, 
    }
}

export default connect(mapStateToProps)(CheckoutSuccess)