import { Link } from 'react-router-dom';

const LocationButtons = ({location_id, location_qr, location}) => {
    return (
        <div className="location-buttons">
            <ul>
                <Link to={'/app/question/' + location_id}>
                    <li>Manage Questions</li>
                </Link>
                {/* <Link to={'/app/reports/' + location_id}>
                    <li>Generate Reports</li>
                </Link> */}
                <Link to={'/app/qrcode/' + location_qr}>
                    <li>Print QR Code</li>
                </Link>
                {/* <Link to={'/app/editmap/2' + location_id}>
                    <li>Change Address</li>
                </Link> */}
                <li className='delete-location-button'>
                    <a href="#delete_location_modal" className="waves-effect modal-trigger">Delete Location</a>
                </li>
                {/* <Link to='/'>
                    <li>Delete Location</li>
                </Link> */}
                <li className='delete-location-button'>
                    { 
                        location.status === 'active' ? 
                        <a href="#suspend_location_modal" className="waves-effect modal-trigger">Suspend Location</a>
                        : 
                        <a href="#unsuspend_location_modal" className="waves-effect modal-trigger">Unsuspend Location</a>
                    }
                </li>

            </ul>
        </div>
    )
}

export default LocationButtons;