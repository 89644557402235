import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router';
import './report.css'

class Reports extends Component {
    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to='/app/signin' />
        if (!auth.emailVerified) return <Redirect to='/app/verifyemail' />

        return (
            <div className="ovfl-aut report-cont">
                <div className='report-parent'>
                    <div className="report-actions">
                        <button className="filled-btn">
                            Print
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth : state.firebase.auth
    }
}

export default connect(mapStateToProps) (Reports)
