
import React, { Component } from 'react';
import VisitorFilters from '../visitors/VisitorFilters';
import LocationButtons from './locationmanagement/LocationButtons';
import LocationStats from './locationmanagement/LocationStats';
import LocSummary from './locationmanagement/LocSummary';
import MoreActions from './MoreActions';
import VisitorList from '../visitors/VisitorList';
import { Redirect } from 'react-router';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import LoadingPage from '../../LoadingPage';
import { deleteLocation, suspendLocation, unSuspendLocation } from '../../../store/actions/locationActions';

class LocationData extends Component {

    state = {
        filter : '',
        from_date : '',
        to_date : '', 
        loc_id : this.props.match.params.id,
    }

    componentDidMount = () => {
        const {  location } = this.props;
        const location_qr = location?.location_qr;

        this.setState({
            location_qr: location_qr
        })
    }

    handleFilter = (filter) => {
        this.setState({
            filter: filter
        })
    }

    handleCustomDate = (filter) => {
        console.log(filter)
        this.setState({
            from_date : filter.from_date,
            to_date : filter.to_date
        })
    }

    clearFilter = () =>{
        this.setState({
            filter: '',
            from_date : '',
            to_date : ''
        })
    }

    deleteLocation = (e) => {
        this.props.deleteLocation(this.state);
        this.props.history.push('/app/dashboard');
        // console.log(this.state.loc_id)
    }

    suspendLocation = (e) => {
        this.props.suspendLocation(this.state);
    }

    unSuspendLocation = (e) => {
        this.props.unSuspendLocation(this.state);
    }

    render() {

        const { auth, location, parent_company, location_visitors } = this.props;
        const id = this.props.match.params.id;

        const location_qr = location?.location_qr;

        if (!auth.uid) return <Redirect to='/app/signin' />
        if (!auth.emailVerified) return <Redirect to='/app/verifyemail' />

        if(location){
            return (
                <div className='management-panel'>

                    <div id="delete_location_modal" className="modal modal-fixed-footer deleting-location">
                        <div className="modal-content">
                        <h4 className='center'>Deleting Location</h4>
                        <p>Deleting your location erases all data including visitor logs. </p>
                        <p>Are you sure you wish to proceed?</p>
                        </div>
                        <div className="modal-footer">
                        <a href="#!" className="modal-close waves-effect waves-green btn-flat">Cancel</a>
                        <span  onClick={this.deleteLocation} className="modal-close waves-effect waves-green btn-flat">Delete</span>
                        </div>
                    </div>

                    
                    <div id="suspend_location_modal" className="modal modal-fixed-footer deleting-location">
                        <div className="modal-content">
                            <h4 className='center'>Suspend Location</h4>
                            <p>Suspending your location renders your QR codes inactive for the entire period of the suspension</p>
                            <p>Are you sure you wish to proceed?</p>
                        </div>
                        <div className="modal-footer">
                            <a href="#!" className="modal-close waves-effect waves-green btn-flat">Cancel</a>
                            <span  onClick={this.suspendLocation} className="modal-close waves-effect waves-green btn-flat">Suspend</span>
                        </div>
                    </div>

                    
                    <div id="unsuspend_location_modal" className="modal modal-fixed-footer deleting-location">
                        <div className="modal-content">
                            <h4 className='center'>Unsuspend Location</h4>
                            <p>Reactivate your account and restore all functionalities. </p>
                            <p>Are you sure you wish to proceed?</p>
                        </div>
                        <div className="modal-footer">
                            <a href="#!" className="modal-close waves-effect waves-green btn-flat">Cancel</a>
                            <span  onClick={this.unSuspendLocation} className="modal-close waves-effect waves-green btn-flat">Reactivate</span>
                        </div>
                    </div>

                    




                    <div className="side-panel">
                        <div className="panel-container z-depth-2  ovfl-aut">
                            <div className="side-panel-content">
                                <LocSummary location = {location} parent_company={parent_company} />
                                <LocationButtons location = {location} location_id={id} location_qr={location_qr} />
                            
                                {/* <LocationSummary location = {location} parent_company={parent_company} />
                                <LocationButtons location = {location} location_id={id} location_qr={location_qr}/> */}
                            </div>
                        </div>
                    </div>
    
                    <div className="main-panel ovfl-aut">
                        <div className="panel-container z-depth-2 ovfl-aut">
                            <div className="main-panel-content">
                                <LocationStats location_visitors = {location_visitors} />
                                <VisitorFilters onSelectFilter = {this.handleFilter} />
    
                                {
                                    this.state.filter || this.state.from_date || this.to_date ? 
                                    <button className="filled-btn" onClick={this.clearFilter}>
                                        Clear filter
                                    </button> : ''
                                }
                                    
    
                                <MoreActions onSelectCustomFilter = {this.handleCustomDate} location_visitors = { location_visitors } />
                                <VisitorList 
                                    location_visitors = { location_visitors } 
                                    location_id={ id } 
                                    filter = {this.state.filter}
                                    from_date = {this.state.from_date}
                                    to_date = {this.state.to_date}
                                />
                                {/* <LocationStats location_visitors = {location_visitors} />
                                <VisitorFilters onSelectFilter = {this.handleFilter}/>
                                {
                                    this.state.filter || this.state.from_date || this.to_date ? 
                                    <button className="filled-btn" onClick={this.clearFilter}>
                                        Clear filter
                                    </button> : ''
                                } */}
                                
                                {/* <CustomFilter onSelectFilter = {this.handleCustomFilter}/> */}
                                {/* <MoreActions onSelectCustomFilter = {this.handleCustomDate} location_visitors = { location_visitors }/>
                                <VisitorList 
                                    location_visitors = { location_visitors } 
                                    location_id={ id } 
                                    filter = {this.state.filter}
                                    from_date = {this.state.from_date}
                                    to_date = {this.state.to_date}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }else {
            return (
                <LoadingPage message = 'Loading location' />
            )
        }

    }
}

const mapStateToProps = (state, ownProps) => {
    
    const id = ownProps.match.params.id;
    const locations = state.firestore.data.locations;
    const location = locations ? locations[id] : null

    const parent_company = location ? location.parent_company : null
    const companies = state.firestore.data.company;
    const company = companies ? companies[parent_company] : null

    // console.log(state.firestore.ordered.visitors)
    
    return {
        auth : state.firebase.auth,
        location: location,
        parent_company : company,
        location_visitors : state.firestore.ordered.visitors
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteLocation: (loca) => dispatch(deleteLocation(loca)),
        suspendLocation: (loca) => dispatch(suspendLocation(loca)),
        unSuspendLocation: (loca) => dispatch(unSuspendLocation(loca))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect( props => [
        { collection : 'visitors', where : [["location", "==", props.match.params.id]] }
    ])
)(LocationData)