import React from 'react';
import { NavLink } from 'react-router-dom';



const SignedOutLinks = () => {
    return (
        <ul className="right hide-on-med-and-down signed-in-links valign-wrapper">
            <li>
                <NavLink activeClassName='active-link' to='/contactpage' className='black-text'>
                    Contact
                </NavLink>                
            </li>
            <li>
                <NavLink activeClassName='active-link' to='/app/signin'className='black-text'>
                    Login
                </NavLink>                
            </li>

            <li>
                <NavLink to='/app/register' className='filled-btn valign-wrapper navlink-btn'>
                    Register
                    <i className="material-icons">arrow_forward</i>
                </NavLink>
            </li>
                

        </ul>
    )
}

export default SignedOutLinks
