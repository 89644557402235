const initState = {
    questionStatus : null
}

const questionReducer = (state = initState, action) => {
    switch (action.type){
        case 'CREATE_QUESTION_SUCCESS' : 
            console.log('question created', action.question);
            return {
                ...state,
                questionStatus : 'success'
            }
            
        case 'CREATE_QUESTION_ERROR' : 
            console.log('create question error', action.err)
            return state;
        
        case 'QUESTION_DELETED_SUCCESS' : 
            console.log('delete question success', action.question)
            return state;

        case 'QUESTION_DELETED_ERROR': 
            console.log('delete item error', action.err)
            return state;

        case 'QUESTION_ADDED_SUCCESS' : 
            console.log('question added from pool', action.type)
            return state;

        case 'QUESTION_ADDED_ERROR' : 
            console.log('question adding failed', action.err)
            return state;

        default : 
            return state

    }
}

export default questionReducer;