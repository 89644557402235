import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { motion } from 'framer-motion';

import { connect } from 'react-redux';
import { signUp } from '../../../store/actions/authActions';
import { Redirect } from 'react-router';
import LoadingPage from '../../LoadingPage';

class ReferralRegister extends Component {

    state = {
        company_name: '',
        first_name: '',
        last_name: '',
        country_code: '',
        phone_number: this.state?.phone || '',
        email: '',
        password: '',
        confirm_password: '',
        authStatus : ''
    }

    componentDidMount = () => {
        this.setState({
            referred_by : this.props.match.params.id
        })
    }
    
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.password === this.state.confirm_password){
            this.setState({
                authStatus : 'loading'
            })
            this.props.signUp(this.state);
            console.log(this.state);
        }else{
            alert('Passwords do not match')
        }
    }
    render() {

        const { auth, registerStatus, authError } = this.props; 
        if (auth.uid) return <Redirect to='/app/dashboard' />

        console.log(this.props.match.params)

        const containerVariants ={
            hidden : {
                x: '100vw'
            },
            visible : {
                x: '0vw',
                transition : { ease: 'easeInOut', duration: 1}
            },
            exit: {
                x: '-100vw',
                transition: {ease: 'easeInOut', duration: 1}
            }
        }
        if(this.state.authStatus === '' || registerStatus === 'error') {
            return (
                <motion.div 
                initial='hidden'
                animate='visible'
                exit='exit'
                variants={containerVariants}
                className='bg-2 ovfl-aut 
                register-page'>
                    <form onSubmit={this.handleSubmit} className="register-form auth-form">
                        <h3>Hello, Welcome To Ingia!</h3>
                        <h4>Enter your details to register</h4>
                        
                        <div className="input-field">
                            <input 
                            placeholder='Type company name here' 
                            id="company_name" 
                            type="text"
                            
                            required 
                            className="validate f-inpt" 
                            onChange={this.handleChange}/>
    
                            <label 
                            className="active" 
                            htmlFor="company_name">Company Name</label>
                        </div>
    
                        <div className="row">
                            <div className="input-field col s6 pl-0">
                                <input 
                                type="text" 
                                id='first_name'
                                required
                                placeholder='Type first name here'
                                className="validate f-inpt"
                                onChange={this.handleChange}/>
    
                                <label 
                                className="active" 
                                htmlFor="first_name">First Name</label>
                            </div>
    
                            <div className="input-field col s6 pr-0">
                                <input 
                                type="text" 
                                id='last_name'
                                required
                                placeholder='Type last name here'
                                className="validate f-inpt"
                                onChange={this.handleChange}/>
    
                                <label 
                                className="active" 
                                htmlFor="last_name">Last Name</label>
                            </div>
                        </div>
    
                        <div className="input-field mb-40">
    
                        <label className="active" htmlFor="number">
                            Phone Number
                        </label> <br></br>

                            <PhoneInput
                            placeholder="Enter phone number"
                            buttonClass='phoneInput'
                            buttonStyle={{
                                backgroundColor: 'rgb(160, 160, 160)', 
                                borderRadius: '50px',
                                // width: '100px'
                            }}
                            inputStyle = {{paddingLeft: '50px'}}
                                international
                                countryCallingCodeEditable={false}
                                onChange={phone => this.setState({ phone })}/>
    
                        </div>
                        
    
                        <div className="input-field ">
                            <input 
                            placeholder='Type email here' 
                            id="email" 
                            type="email"
                            
                            required 
                            className="validate f-inpt" 
                            onChange={this.handleChange}/>
    
                            <label 
                            className="active" 
                            htmlFor="email">Email</label>
                        </div>
    
                        <div className="row">
                            <div className="input-field col s6 pl-0">
                                <input 
                                type="password" 
                                id='password'
                                minLength='8'
                                required
                                placeholder='Type password here'
                                className="validate f-inpt"
                                onChange={this.handleChange}/>
    
                                <label 
                                className="active" 
                                htmlFor="password">Password</label>
                            </div>
    
                            <div className="input-field col s6 pr-0">
                                <input 
                                type="password" 
                                minLength='8'
                                id='confirm_password'
                                required
                                placeholder='Type password here'
                                className="validate f-inpt"
                                onChange={this.handleChange}/>
    
                                <label 
                                className="active" 
                                htmlFor="confirm_password">Confirm Password</label>
                            </div>
                            
                        </div>
                        {
                            authError ? 
                            <div className="center red-text">
                                {authError}
                            </div> : 
                            null
                        }
    
                        <div className="input-field right lt">
                            <button className="filled-btn z-depth-2">
                                Register
                            </button>
                        </div>
    
                    </form>
                </motion.div>
            )
        }else if(this.state.authStatus === 'loading'){
            return (
                <LoadingPage message='Registering' />
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth : state.firebase.auth,
        authError: state.auth.authError,
        registerStatus : state.auth.registerStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ReferralRegister)
