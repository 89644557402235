import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';



import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './store/reducers/rootReducer';
import { Provider, useSelector } from 'react-redux';
import thunk from 'redux-thunk';
import { createFirestoreInstance, reduxFirestore, getFirestore } from 'redux-firestore';
import { ReactReduxFirebaseProvider, getFirebase, isLoaded } from 'react-redux-firebase';
import fbConfig from './config/fbConfig';
import firebase from './config/fbConfig';
import LoadingPage from './components/LoadingPage';


const store = createStore(rootReducer, 
  compose(
  applyMiddleware(thunk.withExtraArgument({getFirebase, getFirestore})), 
  reduxFirestore(firebase),
  ))

const rrfConfig = {
  useFirestoreForProfile: true,
  userProfile: "users",
};

const rrfProps ={
  firebase,
  fbConfig,
  // config: fbConfig,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <LoadingPage message='Loading Ingia'/>;
  return children
}


ReactDOM.render(
  <React.StrictMode>
    <Provider store={ store }>
      <ReactReduxFirebaseProvider { ...rrfProps }>
        <AuthIsLoaded>
          <App />
        </AuthIsLoaded>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

