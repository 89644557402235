

const initState = {
    authError: null,
    registerStatus : null,
    signinStatus : null
}

const authReducer = (state = initState, action) => {

    switch(action.type) {
        case 'LOGIN_ERROR':
            console.log(action.err.message);
            return {
                ...state, 
                authError: action.err.message,
                signinStatus : 'error'
            }
        
        case 'LOGIN_SUCCESS':
            console.log('login success');
            return {
                ...state, 
                authError: null
            }

        case 'SIGNOUT_SUCCESS':
            console.log('signout success');
            return state

        case 'SIGNUP_SUCCESS': 
            console.log('signup success');
            return {
                ...state,
                authError : null,
            }
            
        
        case 'SIGNUP_ERROR': 
            console.log('signup error');
            console.log(action.err.message);
            return {
                ...state,
                authError : action.err.message,
                registerStatus : 'error'

            }

        case 'EMAIL_VERIFICATION_SUCCESS':
            console.log('email verification success');
            return {
                ...state,
                authError : null
            }

        case 'EMAIL VERIFICATION_ERROR':
            console.log('email verification error');
            return {
                ...state,
                authError : 'Verificiation Failed'
            }

        case 'PASSWORD_RESET_SUCCESS':
            console.log('password reset success');
            return {
                ...state,
                authError : 'success'
            }

        case 'PASSWORD_RESET_ERROR':
            console.log('password reset error');
            console.log(action.err.message);
            return {
                ...state,
                authError : action.err.message
            }

        default:
            return state;
    }
}

export default authReducer;