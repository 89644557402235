import React from 'react';
import centerbtn from '../../../assets/images/art/btnicon.png';
import './location.css';

const LocationSummary = ({ location }) => {
    return (
        <ul className="location-tile z-depth-2 valign-wrapper ">
            <img src={centerbtn} alt=""/>
            <div className="location-summary-text">
                <h4>{ location.location_name }</h4>
                <h5>{ location.country }</h5>
            </div>
            { 
                location.status === 'active' ?
                null
                : 
                <div className="location-suspended">Suspended</div>
            }
        </ul>
    )
}

export default LocationSummary
