import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
// import CustomFilter from '../visitors/CustomFilter';



class MoreActions extends Component {

    state = {
        from_date : '',
        to_date : ''
    }

    componentDidMount() {
        const M = window.M;
        M.AutoInit();
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleFilter = (e) => {
        var filt = this.state
        this.props.onSelectCustomFilter(filt);
        console.log(this.state)
    }


    render() {
        const location_visitors = this.props.location_visitors;
        console.log('some', this.state)
        
        return (
            <ul className='collapsible' data-collapsible="accordion">
                <li>
                    <div className="collapsible-header more-actions">
                        <span>More Actions</span>
                        <i className="material-icons">arrow_drop_down</i>
                    </div>
                    <div className="collapsible-body more-actions-body">

                    <span className="custom-date-filter">Report Period</span>
                        <div className="report-period z-depth-1">

                            <div className="input-field col s3 pl-0">
                                <input 
                                type="date" 
                                id='from_date'
                                required
                                placeholder='Date'
                                className="validate f-inpt"
                                onChange={this.handleChange}/>

                                <label 
                                className="active" 
                                htmlFor="from_date">From Date</label>
                            </div>

                            <div className="input-field col s3 pl-0">
                                <input 
                                type="date" 
                                id='to_date'
                                required
                                placeholder='Date'
                                className="validate f-inpt"
                                onChange={this.handleChange}/>

                                <label 
                                className="active" 
                                htmlFor="to_date">To Date</label>
                            </div>

                            <button onClick={this.handleFilter} className="filled-btn date-filt-btn">Filter</button>

                        </div>

                        <div className="buttons-container">
                            {
                                location_visitors 
                                ? 
                                <CSVLink filename='Visitor Data.csv' data={location_visitors} className="filled-btn export-btn">Export to CSV</CSVLink>
                                : null
                            }
                            {/* <button className="filled-btn export-btn" onClick={() => this.handleExport('One')}>Export to CSV</button> <br /> */}
                        </div>

                        
                    </div>
                </li>
            </ul>
        )
    }
}

export default MoreActions