import { Component } from 'react'
import { Route, Switch } from 'react-router'
import CheckError from '../app/mobile/CheckError'
import CheckIn from '../app/mobile/CheckIn'
import CheckinSuccess from '../app/mobile/CheckinSuccess'
import CheckinType from '../app/mobile/CheckinType'
import Checkout from '../app/mobile/Checkout'
import CheckoutSuccess from '../app/mobile/CheckoutSuccess'
import InOrOut from '../app/mobile/InOrOut'
import MobileSplash from '../app/mobile/MobileSplash'
import QRScanError from '../app/pages/QRScanError'

class MobilePages extends Component {

    state = {
        splashed : false
    }

    componentDidMount() {
        this.id = setTimeout(() => this.setState({splashed: true}), 2000) 
    }

    componentWillUnmount () {
        clearTimeout(this.id) 
    }

    render(){
        return this.state.splashed ? 
            <div className='mobile-page'>
                <Switch>
                    <Route path = '/mobile/inorout/:id' component={CheckinType} />
                    <Route exact path = '/mobile/checkin/:id' component={CheckIn} />
                    <Route exact path = '/mobile/checkout/:id' component={Checkout} />

                    <Route path='/mobile/checkin/success/:id' component={CheckinSuccess} />
                    <Route path='/mobile/checkout/success/:id' component={CheckoutSuccess} />
                    <Route path='/mobile/check/error' component={CheckError} />


                    <Route path = '/mobile/type/:id' component = { InOrOut } />


                    <Route path='/mobile/checkoutr' component={QRScanError} />
                </Switch>
            </div>
            :
            <MobileSplash />
    }
}

export default MobilePages
