

import React, { Component } from 'react'

class EditMap extends Component {

    state = { 
        new_address : '',
        location_id : ''
    }

    componentDidMount = () =>{
        this.setState({
            location_id : this.props.match.params.id
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.state)
    }
    render() {

        const id = this.props.match.params.id;
        console.log(id)
        return (
            <div className='edit-map'>
            <div className="edit-map-fields z-depth-2">
                <form onSubmit={this.handleSubmit} className="edit-map-form"> 
                    <div className="input-field mt-40">
                        <input  
                        type="text" 
                        id='new_address'
                        required
                        placeholder='Type or paste address here'
                        className="validate f-inpt"
                        onChange={this.handleChange}
                        />
                        <label 
                        className="active" 
                        htmlFor="new_address">Location Address</label>
                    </div>

                    <div className="input-field right">
                        <button className="filled-btn z-depth-2 mb-80">
                            Edit Address
                        </button>
                    </div>
                </form>
            </div>

            <div className="edit-map-container">
            <iframe src="https://what3words.com/" className='edit-map-frame z-depth-2' title='google' frameBorder="0" allow="clipboard-read; clipboard-write"></iframe>
            </div>
        </div>
        )
    }
}

export default EditMap

