
import { motion } from 'framer-motion';

const PrivacyPolicy = () => {

    const containerVariants = { 
        hidden : {
            x: '100vw'
        },
        visible : {
            x: '0vw',
            transition : { ease: 'easeInOut', duration: 1}
        },
        exit: {
            x: '-100vw',
            transition: {ease: 'easeInOut', duration: 1}
        }
    }


    return (
        <motion.div 
        initial='hidden'
        animate='visible'
        exit='exit'
        variants={containerVariants} 
        className="privacy-policy-parent bg-2 ovfl-aut">
            <div className="landing-content z-depth-3">
                <div className="content-banner ingia-blue-bg mb-50">
                    INGIA.CO PRIVACY POLICY
                </div>
                <h5>1. Introduction</h5>
                <h5>Welcome to Acuvera Systems Limited.</h5>
                <h5>Acuvera Systems Limited operates www.ingia.co (hereinafter referred to as “Service”).</h5>

                <p>
                 Our Privacy Policy governs your visit to ingia.co, and explains how we collect, safeguard and disclose information that results from your use of our Service. 
                </p>

                <p>
                 We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
                </p>

                <h5>2. Definitions</h5>
                <p>
                    <span className="bold-text">SERVICE</span>  means the www.ingia.co website operated by Acuvera Systems Limited.

                </p>
                <p>
                    <span className="bold-text">PERSONAL DATA</span>   means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
                </p>
                <p>
                    <span className="bold-text">USAGE DATA</span>  is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).
                </p>
                <p>
                    <span className="bold-text">COOKIES</span>  are small files stored on your device (computer or mobile device).
                </p>
                <p>
                    <span className="bold-text">DATA CONTROLLER</span>  means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.
                </p>
                <p>
                    <span className="bold-text">DATA PROCESSORS (OR SERVICE PROVIDERS) </span>  means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.
                </p>
                <p>
                    <span className="bold-text">DATA SUBJECT</span>   is any living individual who is the subject of Personal Data.

                </p>
                <p>
                    <span className="bold-text">THE USER</span>   is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.

                </p>

                <h5>3. Information Collection and Use</h5>
                <p>
                    We collect several different types of information for various purposes to provide and improve our Service to you.
                </p>

                <h5>4. Type of Data Collected</h5>
                <h5>Personal Data</h5>
                <p>
                    While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:
                </p>

                <ol className='ml-20'>
                    <li>Email Address</li>
                    <li>First name and last name</li>
                    <li>Identification Number</li>
                    <li>Phone number</li>
                    <li>Vehicle information</li>
                    <li>Person or department being visited</li>
                    <li>Badge number</li>
                    <li>Cookies and usage data</li>
                </ol>
                <p>We will not use your Personal Data to contact you with newsletters, marketing or promotional materials.</p>

                <h5>Usage Data</h5>
                <p>
                    We may collect information that your browser sends whenever you visit our Service or when you access Service by or through any device (“Usage Data”).
                </p>
                <p>
                    This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                </p>
                <p>
                    When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.
                </p>
                <h5>Tracking Cookies Data</h5>
                    <p>
                        We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
                    </p>
                    <p>

                        Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.
                    </p>
                    <p>
                        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
                    </p>
                    <p>Examples of cookies we use:</p>
                    <ol className='ml-20'>
                        <li><span className="bold">Session Cookies: </span> We use Session Cookies to operate our Service.</li>
                        <li><span className="bold">Preference Cookies: </span> We use Preference Cookies to remember your preferences and various settings.</li>
                        <li><span className="bold">Security Cookies: </span> We use Security Cookies for security purposes.</li>
                        <li><span className="bold">Advertising Cookies: </span>Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</li>
                    </ol>

                    <h5>Other Data</h5>
                    <h5>5. Use of Data</h5>
                    <h5>Acuvera Systems Limited uses the collected data for various purposes:</h5>

                    <ol className="ml-20">
                        <li> to notify you about changes to our Service;</li>
                        <li> to provide and maintain our Service;</li>
                        <li> to allow you to participate in interactive features of our Service when you choose to do so;</li>
                        <li> to provide customer support;</li>
                        <li> to gather analysis or valuable information so that we can improve our Service;</li>
                        <li> to monitor the usage of our Service;</li>
                        <li> to detect, prevent and address technical issues;</li>
                        <li> to fulfil any other purpose for which you provide it;</li>
                        <li> to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</li>
                        <li> to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</li>
                        <li> to provide you with news, special offers and general information about other goods,
                            services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</li>
                        <li> in any other way we may describe when you provide the information;</li>
                        <li> for any other purpose with your consent.</li>
                    </ol>

                    <h5>6. Retention of Data</h5>
                    <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                    <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
                    
                    <h5>7. Transfer of Data</h5>
                    <p>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your country of registration.  This means the data protection laws may differ from those of your jurisdiction.</p>
                    <p>If you are located outside Kenya and choose to provide information to us, please note that we transfer the data, including Personal Data, to Kenya and process it there.</p>
                    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                    <p>Acuvera Systems Limited will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

                    <h5>8.Disclosure of Data</h5>
                    <p>We may disclose personal information that we collect, or you provide:</p>

                    <ol className="ml-20">
                        <li>
                            <span className="bold">Disclosure for Law Enforcement.</span>
                            Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.
                        </li>
                        <li>
                            <span className="bold">Business Transaction.</span>
                            If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be transferred.
                        </li>
                        <li>
                            <span className="bold">Other cases. We may disclose your information also:</span>
                        </li>


                            <li>to our subsidiaries and affiliates;</li>
                            <li>to contractors, service providers, and other third parties we use to support our business;</li>
                            <li>to fulfill the purpose for which you provide it;</li>
                            <li>for the purpose of including your company’s logo on our system and website;</li>
                            <li>for any other purpose disclosed by us when you provide the information;</li>
                            <li>with your consent in any other cases;</li>
                            <li>if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</li>
                    </ol>
                    <h5>9. Security of Data</h5>
                    <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                    <h5>10. Service Providers</h5>
                    <p>We may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.
                    These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                    <h5>11. Analytics</h5>
                    <p>
                        We may use third-party Service Providers to monitor and analyze the use of our Service.
                    </p>
                    <h5>12. CI/CD tools</h5>
                    <p>
                        We may use third-party Service Providers to automate the development process of our Service.
                    </p>
                    <h5>13. Behavioral Remarketing</h5>
                    <p>We do not use remarketing services to advertise on third party websites to you after you visited our Service. </p>
                    <h5>14. Payments</h5>
                    <p>We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
                    <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>

                    <h5>15. Links to Other Sites</h5>
                    <p>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                    <h5>16. Children’s Privacy</h5>
                    <p>Our Services are not intended for use by children under the age of 18 (“Child” or “Children”).</p>
                    <p>We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</p>
                    <h5>17. Changes to This Privacy Policy</h5>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                    <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.</p>
                    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                    <h5>18. Contact Us</h5>
                    <p>If you have any questions about this Privacy Policy, please contact us by email: <a href="mailto:name@email.com">support@ingia.co</a></p>

 
            </div>
        </motion.div>
    )
}

export default PrivacyPolicy;