import React from 'react'

const PrimaryQuestions = () => {
    return (
        <div className='primary-questions'>
               <div className='visitor-list z-depth-2 scroll'>
                    <div className="">
                        <h5>Primary Questions</h5>
                    <table className="bordered stripped highlight">
                        <thead>
                            <tr>
                                <th>Label</th>
                                <th>Placeholder</th>
                                <th>Required</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>First Name</td>
                                <td>First Name...</td>
                                <td>true</td>
                                <td>text</td>
                            </tr>
                            <tr>
                                <td>Last Name</td>
                                <td>Last Name...</td>
                                <td>true</td>
                                <td>text</td>
                            </tr>
                            <tr>
                                <td>Mobile Number</td>
                                <td>Mobile Number...</td>
                                <td>true</td>
                                <td>tel</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    
                </div>
        </div>
    )
}

export default PrimaryQuestions
