import React, { Component } from 'react';
import LoadingPage from '../../LoadingPage';
import moment from 'moment';

class VisitorList extends Component {

    state = {

    }

    handleVisitor = (e) => {
        const open = document.getElementById('visitorModal');
        open.style.display = 'block'
        this.setState({
            e : e
        }, ()=>{console.log(this.state)});
    }

    handleClose = () => {
        const close = document.getElementById('visitorModal');
        close.style.display = 'none'
    }

    render() {

        const location_visitors = this.props.location_visitors;
        const location_id = this.props.location_id;
        const filter = this.props.filter;
        const from_date = this.props.from_date;
        const to_date = this.props.to_date;

        const visitor = this.state.e ? this.state.e : ''
        
        const startDate = new Date(from_date);
        const endDate = new Date(to_date);

        const timeIn = visitor.time_in ? visitor.time_in : '';
        const timeOut = visitor.time_out ? visitor.time_out : '';

        if(location_visitors) {
            return (
                <div className='visitor-list z-depth-2 scroll'>
                    <div className="">
                        <div className="visitor-modal-parent" id='visitorModal'>
                            <i className="material-icons close-btn" onClick={this.handleClose}>close</i>
                            <div className="visitor-modal">
                                <h5 className='center visitor-title'>{this.state.e ? this.state.e.first_name + ' ' + this.state.e.last_name : '' }</h5>
                                <div className="row">
                                    <div className="col s6"> 
                                        {
                                        
                                        visitor ? 
                                        Object.entries(visitor)
                                        .filter(([key, value]) => ![
                                            'time_in',
                                            'time_out',
                                            'location',
                                            'added',
                                            'id',
                                        ].includes(key))
                                        .map(pair => {
                                            const key = pair[0]
                                            const value = pair[1]
                                            return(
                                                <div>
                                                    {
                                                        value ? (
                                                    <h5 className="visitor-data-key">{key.replace(/_/g, ' ').toUpperCase()} 
                                                    :
                                                    <span className="visitor-data-value">{value}</span></h5>
                                                        ) : null
                                                    } 
                                                    
                                                </div>
                                                
                                            )
                                        }) 
                                        : null
                                        }
                             
                                    </div>
                                    <div className="col s6"> 
                                      <span className='visitor-data-key'>Time In:</span>   {timeIn ? moment(timeIn.toDate()).format('lll') : '-'} <br /><br />
                                      <span className='visitor-data-key'>Time Out:</span>  {timeOut ? moment(timeOut.toDate()).format('lll') : '-'}
                                    </div>
                                </div>

                            </div>
                        </div>
                        {
                            from_date && to_date ? 
                            <div>
                            <table className="bordered stripped highlight">
                                <thead>
                                    <tr>
                                        <th>Full Name</th>
                                        {/* <th>ID/Passport</th> */}
                                        <th>Phone</th>
                                        {/* <th>Office/Person</th>
                                        <th>Reason</th> */}
                                        <th>Time In</th>
                                        <th>Time Out</th>
                                    </tr>
                                </thead>
                                {location_visitors && location_visitors
                                .filter(location_visitor => location_visitor.location === location_id && moment(location_visitor.time_in.toDate()).isBetween(startDate, endDate) === true)
                                // .filter(location_visitor => location_visitor.location === location_id && moment(location_visitor.time_in.toDate()).format('l') === filter_day)
                                .sort((a, b) => b.time_in - a.time_in)
                                // .sort((a,b) => (a.time_in > b.time_in) ? 1 : 1)
                                .map(
                                    location_visitor => {
                                        return (
                                            <tbody key={location_visitor.id}>
                                                <tr className='visitor-row' onClick={() => this.handleVisitor(location_visitor)}>
                                                    <td> 
                                                    { location_visitor.first_name ? location_visitor.first_name + " " + location_visitor.last_name : '-' }
                                                    </td>
                                                    {/* <td>
                                                        { location_visitor.id_number ? location_visitor.id_number : '-' }
                                                    </td> */}
                                                    <td>
                                                        { location_visitor.phone_number ? location_visitor.phone_number : '-' }
                                                    
                                                    </td>
                                                    {/* <td>
                                                        { location_visitor.office ? location_visitor.office : '-' }
                                                    </td>
                                                    <td>
                                                        { location_visitor.reason ? location_visitor.reason : '-' }
                                                    </td> */}
                                                    <td>{moment(location_visitor.time_in.toDate()).format('lll')}</td>
                                                    <td>
                                                        {location_visitor.time_out ? moment(location_visitor.time_out.toDate()).format('lll') : '-'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    }
                                )}
                                </table>
                            </div> 
                            : 
                            <div>
                        {
                            filter ? 
                            <table className="bordered stripped highlight">
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    {/* <th>ID/Passport</th> */}
                                    <th>Phone</th>
                                    {/* <th>Office/Person</th>
                                    <th>Reason</th> */}
                                    <th>Time In</th>
                                    <th>Time Out</th>
                                </tr>
                            </thead>
                            {location_visitors && location_visitors
                            .filter(location_visitor => location_visitor.location === location_id && moment(location_visitor.time_in.toDate()).isSame(new Date(), filter) === true)
                            // .filter(location_visitor => location_visitor.location === location_id && moment(location_visitor.time_in.toDate()).format('l') === filter_day)
                            .sort((a, b) => b.time_in - a.time_in)
                            // .sort((a,b) => (a.time_in > b.time_in) ? 1 : 1)
                            .map(
                                location_visitor => {
                                    return (
                                        <tbody key={location_visitor.id}>
                                            <tr className='visitor-row' onClick={() => this.handleVisitor(location_visitor)}>
                                                <td> 
                                                  { location_visitor.first_name ? location_visitor.first_name + " " + location_visitor.last_name : '-' }
                                                </td>
                                                {/* <td>
                                                    { location_visitor.id_number ? location_visitor.id_number : '-' }
                                                </td> */}
                                                <td>
                                                    { location_visitor.phone_number ? location_visitor.phone_number : '-' }
                                                
                                                </td>
                                                {/* <td>
                                                    { location_visitor.office ? location_visitor.office : '-' }
                                                </td>
                                                <td>
                                                    { location_visitor.reason ? location_visitor.reason : '-' }
                                                </td> */}
                                                <td>{moment(location_visitor.time_in.toDate()).format('lll')}</td>
                                                <td>
                                                    {location_visitor.time_out ? moment(location_visitor.time_out.toDate()).format('lll') : '-'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    )
                                }
                            )}
                            </table> : 
                        
                            <table className="bordered stripped highlight">
                                <thead>
                                    <tr>
                                        <th>Full Name</th>
                                        {/* <th>ID/Passport</th> */}
                                        <th>Phone</th>
                                        {/* <th>Office/Person</th>
                                        <th>Reason</th> */}
                                        <th>Time In</th>
                                        <th>Time Out</th>
                                    </tr>
                                </thead>
                                {location_visitors && location_visitors
                                .filter(location_visitor => location_visitor.location === location_id)
                                .sort((a, b) => b.time_in - a.time_in)
                                // .sort((a,b) => (a.time_in > b.time_in) ? 1 : 1)
                                .map(
                                    location_visitor => {
                                        return (
                                            <tbody key={location_visitor.id}>
                                                <tr className='visitor-row' onClick={() => this.handleVisitor(location_visitor)}>
                                                    <td> 
                                                    { location_visitor.first_name ? location_visitor.first_name + " " + location_visitor.last_name : '-' }
                                                    </td>
                                                    {/* <td>
                                                        { location_visitor.id_number ? location_visitor.id_number : '-' }
                                                    </td> */}
                                                    <td>
                                                        { location_visitor.phone_number ? location_visitor.phone_number : '-' }
                                                    
                                                    </td>
                                                    {/* <td>
                                                        { location_visitor.office ? location_visitor.office : '-' }
                                                    </td>
                                                    <td>
                                                        { location_visitor.reason ? location_visitor.reason : '-' }
                                                    </td> */}
                                                    <td>{moment(location_visitor.time_in.toDate()).format('lll')}</td>
                                                    <td>
                                                        {location_visitor.time_out ? moment(location_visitor.time_out.toDate()).format('lll') : '-'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    }
                                )}
                            </table>
                        }
                            </div>
                        }
                    </div>
                </div>
            )
        }else {
            return (
                <LoadingPage message='Loading Visitors' />
            )
        }
        

    }
}

export default VisitorList
