import React from 'react'

const  LocationStats = (props) => {
    
    const location_visitors = props.location_visitors

    const total_visists = location_visitors && location_visitors.length
    const open_visits = location_visitors && location_visitors.filter(
        location_visit => location_visit.time_out === ""
    ).length

    const closed_visits = location_visitors && location_visitors.filter(
        location_visit => location_visit.time_out !== ""
    ).length

    // const today = new Date().getTime();

    // const today_visits = location_visitors && location_visitors.filter(
    //     location_visit => location_visit.time_in === new Date()
    // ).length

    return (
        <div className='location-stats z-depth-1 center'>
               <table className="centered">
                    <thead>
                    <tr>
                        
                        <th>Total Visits</th>
                        <th>Open Visits</th>
                        <th>Closed Visits</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td>{  total_visists }</td>
                        <td> { open_visits } </td>
                        <td>{ closed_visits }</td>
                    </tr>
                    </tbody>
                </table>
        </div>
    )
}

export default LocationStats
