import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import ingiaLogo from '../../../assets/images/logo/logo-1.png'

const VerifyAccount = (props)=> {

    const { auth } = props;

    const handleClick = () => {
       window.location.href='/app/dashboard'
    }
    if (auth.emailVerified) return <Redirect to='/app/dashboard' />
    if (!auth.uid) return <Redirect to='/app/signin' />
    return (
        <div className='verify-email z-depth-2'>
            <div className="img-area">
                <img src={ingiaLogo} alt=""/>
                <h3>Verify your email address</h3>
                <h4>Please verify your email to prove you own the email. A verification link has been sent to the registered email.</h4>
                <h5>Didn't receive email? Send one now.</h5>
                <button className="filled-btn" onClick={handleClick}>
                    {/* Get Verification Link */}
                    Confirm Verification
                </button>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        auth : state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount)
