import React, { Component } from 'react'

import './map.css'

export class Maps extends Component {
    render() {
        return (
            <div className='map-parent'>
                {/* <what3words-address words="daring.lion.race"/> */}
                <iframe title='map' className='map-frame' src="https://what3words.com/" />
            </div>
        )
    }
}

export default Maps
