import React from 'react';
import './mobile.css';
import splashImage from '../../../assets/images/logo/Ingia_Logo Design-12.png'

function MobileSplash() {
    return (
        <div className='mobile-splash'>
            <img src={ splashImage } alt="splash" />
        </div>
    )
}

export default MobileSplash
