import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import LocSummary from '../locations/locationmanagement/LocSummary'
import QuestionButtons from './QuestionButtons'
import QuestionList from './QuestionList'
// import SecondaryQuestions from './SecondaryQuestions'
import { createQuestion } from '../../../store/actions/questionActions'
import PrimaryQuestions from './PrimaryQuestions'

class Question extends Component {

    state = {
        label : '',
        place_holder : '',
        required : false,
        type : 'text'
    }

    componentDidMount = (e) => {
        this.setState({
            location : this.props.match.params.id
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.createQuestion(this.state)
        console.log(this.state)
        this.handleClose();
    }

    handleClose = (e) => {
        const close = document.getElementById('addQuestion');
        close.style.display = 'none'
    }
    handleModal = (e) => {
        const open = document.getElementById('addQuestion');
        open.style.display = 'block'
    }
    handleCheck = (e) => {
        this.setState({
            [e.target.id] : e.target.checked,
        })
    }
    handleRadio = (e) => {

        const { name, value } = e.target;
        if(e.target.value === 'text'){
            this.setState({
                [name]: value,
                order_by : 'j'
            })
        }else if(e.target.value === 'checkbox'){
            this.setState({
                [name] : value,
                checked : false,
                name : this.state.label.toLowerCase(),
                order_by : 'rg',
                value : false
            })
        }else if (e.target.value === 'radio'){
                this.setState({
                    [name] : value,
                    checked : false,
                    name : this.state.label.toLowerCase(),
                    order_by : 'rb',
                    value : false
                })
        }
    }
    render() {
        
        console.log(this.props.match.params.id)
        
        const { auth, location, parent_company, form_items, location_items } = this.props;
        // const { auth, location, parent_company, form_items, location_items, questionStatus } = this.props;
        if (!auth.uid) return <Redirect to='/app/signin' />
        if (!auth.emailVerified) return <Redirect to='/app/verifyemail' />

        const id = this.props.match.params.id
        const location_qr = this.props.location?.location_qr
        return (
            <div className='management-panel'>
                <div className="add-question-modal valign-wrapper" id='addQuestion'>
                <i className="material-icons close-btn" onClick={this.handleClose}>close</i>
                    <div className="add-question-content z-depth-2">
                        <form onSubmit={this.handleSubmit} className="question-form">
                            <h4>Add Item</h4>
                            <div className="row">
                                <div className="input-field col s6 pl-0">
                                    <input 
                                    type="text" 
                                    id='label'
                                    required
                                    placeholder='Enter question label'
                                    className="validate f-inpt"
                                    onChange={this.handleChange}/>

                                    <label 
                                    className="active" 
                                    htmlFor="label">Question Label</label>
                                </div>

                                <div className="input-field col s6 pr-0">
                                    <input 
                                    type="text" 
                                    id='place_holder'
                                    required
                                    placeholder='Enter place holder'
                                    className="validate f-inpt"
                                    onChange={this.handleChange}/>

                                    <label 
                                    className="active" 
                                    htmlFor="place_holder">Place Holder</label>
                                </div>
                            </div>
                            <div className="mandatory-field left mb-20">
                                <label className='fl'>
                                    <input type='checkbox'
                                    checked = {this.state.required}
                                    className='filled-in'
                                    id='required'
                                    onChange={this.handleCheck}
                                    value='required field' />
                                    <span className=''>Mandatory Field</span>
                                </label>
                            </div>
                            <br />
                            <div className="radio-buttons">
                                <label>
                                    <input name="type" type="radio" value='text' onChange={this.handleRadio} />
                                    <span>Text</span>
                                </label>
                                <br />
                                
                                <label>
                                    <input name="type" type="radio" value='checkbox' onChange={this.handleRadio} />
                                    <span>Checkbox</span>
                                </label>
                                <br />
                                <label>
                                    <input name="type" type="radio" value='radio' onChange={this.handleRadio} />
                                    <span>Yes/No Question</span>
                                </label>
                            </div>
                            
                            <button className="filled-btn right create-question">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
                <div className="side-panel">
                    <div className="panel-container z-depth-2  ovfl-aut">
                        <div className="side-panel-content">
                            <LocSummary location =  { location } parent_company={parent_company} />
                            <QuestionButtons location_id = {id} location_qr = { location_qr } />
                        </div>
                    </div>
                </div>
                <div className="main-panel ovfl-aut">
                    <div className="panel-container z-depth-2 ovfl-aut">
                        <div className="main-panel-content">
                            <button className="filled-btn right mb-20 mt-20" onClick={this.handleModal}>Add question</button>
                            <PrimaryQuestions />
                           <QuestionList form_items = { form_items } location_items = { location_items } location_id = {id}  />
                           
                           {/* <SecondaryQuestions form_items = { form_items } location_id = {id}/> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    
    const id = ownProps.match.params.id;
    const locations = state.firestore.data.locations;
    const location = locations ? locations[id] : null

    const parent_company = location ? location.parent_company : null
    const companies = state.firestore.data.company;
    const company = companies ? companies[parent_company] : null

    return {
        questionStatus : state.question.questionStatus,
        auth : state.firebase.auth,
        location : location,
        parent_company : company,
        form_items : state.firestore.ordered.form_items,
        location_items : state.firestore.ordered.location_items
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createQuestion: (que) => dispatch(createQuestion(que))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Question)
