import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import logo from '../../../assets/images/logo/Ingia_Logo Design-03.svg';



const MobileNav = () => {
    return (
        <div className="navbar-fixed">
            <nav className="nav-wrapper grey lighten-3 z-depth-1 mobile-nav valign-wrapper">

                <Link to='/' className='brand-logo black-text left'>
                    <img src={logo} className="navbar-logo" alt='company-logo'/>
                </Link>

            </nav>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {

    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
        { collection : 'locations' },
        { collection : 'visitors' },
        { collection : 'form_items' },
        { collection : 'location_items' }
    ])
)(MobileNav)
