

// ADD QUESTION
export const createQuestion = (question) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        // const adminId = getState().firebase.auth.uid;
        
        const joiner = question.label;
        const place_holder = question.place_holder + '...';
        const joined = joiner.replace(/ /g,"_");
        const f_id = joined.toLowerCase()


        firestore.collection('location_items').add({
            ...question,
            f_id : f_id,
            place_holder : place_holder,
            created_on : new Date()
        }).then((que) => {
            return ([
                dispatch({
                    type : 'CREATE_QUESTION_SUCCESS', question,
                })
            ])
        }).catch((err) =>{
            dispatch({
                type : 'CREATE_QUESTION_ERROR', err
            })
        })
    }
}

// DELETE QUESTION 
export const deleteQuestion = (question) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        
        firestore.collection('location_items').doc(question.id).delete()
        .then((que) => {
            return firestore.collection('bin').add({
               ...question
            })
        })
        .then(() => {
            dispatch({ type: 'QUESTION_DELETED_SUCCESS', question });
        }).catch((err) => {
            dispatch({ type: 'QUESTION_DELETED_ERROR', err});
        })
    }
} 

// ADD FROM POOL 
export const addFromPool = (question, location) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        

        firestore.collection('location_items').add({
            ...question
        }).then((que) => {
            dispatch({ type: 'QUESTION_ADDED_SUCCESS', que });
        }).catch((err) => {
            dispatch({ type: 'QUESTION_ADDED_ERROR', err});
        })
    }
}