import { uploadLogo } from '../../../store/actions/setUpActions'

import React, { useState } from 'react'
import firebase, { storage } from '../../../config/fbConfig';

import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import uploadimage from '../../../assets/images/art/uploadthinblue.png'

// import { uploadLogo } from '../../../store/actions/setUpActions'

const CompanyLogo = (props) => {
    const [image, setImage] = useState(null);
    const [displaySelected, setSelected] = useState(null);
    const [loading, setLoading] = useState(false)
    const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
    let inputFile = '';
    const firestore = firebase.firestore();

    
    const { auth } = props;
    if (!auth.uid) return <Redirect to='/app/signin' />
    if (!auth.emailVerified) return <Redirect to='/app/verifyemail' />


    const handleChange = e => {
        if (e.target.files[0]){
            setImage(e.target.files[0]);
            setSelected(URL.createObjectURL(e.target.files[0]))
        }
    };


    const company = props.match.params.id


    const handleUpload = () => {
        const uploadTask = storage.ref(`companylogos/${image.name}`).put(image);
        setLoading(true);
        uploadTask.on(
            "state_changed",
            snapsot => {},
            error => {
                console.log(error);
            },
            () => {
                storage.ref("companylogos")
                .child(image.name)
                .getDownloadURL()
                .then((url) => {
                    return ([
                        firestore.collection('company').doc(company).update({
                            company_logo : url
                        }),
                        setLoading(false),
                        props.history.push('/app/imageuploadsuccess')
                    ])
                });
            }
        )
    };

    const uploadClick = e => {
        e.preventDefault();
        inputFile.click();
        return false;
      };
    

    if(loading === false){
            return (
                <div className='bg-2'>
                <div className="logo-img-form">
                    <h3>Set your company logo</h3>
                    <div className="logo-area valign-wrapper z-depth-1 ">
                        <img src={ displaySelected ? displaySelected : uploadimage } alt="" className='upload-icon' n/>
                    </div>
                    <div className="company-logo-btns">
                        <div className="attach-photo">
                            <button className="filled-btn" onClick={uploadClick}>
                                Attach logo
                            </button>

                            <label htmlFor="" className="style-inpt">
                                <input 
                                onChange = {handleChange}
                                accept={acceptedFileTypes}
                                style={{display: 'none'}} 
                                type='file'
                                ref={input => {
                                    // assigns a reference so we can trigger it later
                                    inputFile = input;
                                  }}
                                 />
                            </label>
                        </div>

                        <div className="submit-photo file-path-wrapper">
                            <button className="filled-btn" onClick={handleUpload}>Upload</button>
                        </div>
                    </div>
                </div>
            </div>
            )
    }else{
        return (
            <div className="uploading-image center">
                Uploading...
                <div className="progress loading-progress">
                    <div className="indeterminate"></div>
                </div>
            </div>
        )
    }

}


const mapStateToProps = (state) => {

    return {
        auth : state.firebase.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadLogo : (logo) => dispatch(uploadLogo(logo))
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (CompanyLogo)