import React from 'react';
import { Link } from 'react-router-dom';
import guard from '../../../../assets/images/art/guard.png'

const LocSummary = (props) => {

    const location = props.location;
    const parent_company = props.parent_company;

    return (
        <div className='summary-parent z-depth-0'>
            <ul className="summary-one collection">

                <li className="location-summary-panel">
                    <div className="summary-circle z-depth-1">
                        <Link to={'/app/companylogo/' + parent_company?.company_id}>
                            <img src={parent_company ? parent_company.company_logo : guard} alt="" className="circle sum-image" />   
                        </Link>
                    </div>

                    <div className="summary-text">
                        <h4>
                            { location.location_name}
                        </h4>
                        <h5>
                            { location.country}
                        </h5>
                    </div>
                    
                </li>
                {/* <li className="avatar collection-item">
                    <Link to={'/app/companylogo/' + parent_company?.company_id}>
                        <img src={parent_company ? parent_company.company_logo : guard} alt="" className="circle sum-image" />   
                    </Link>
                    <span className="title comp-name">
                    {location?.location_name}
                    </span>
                    <p>
                        {location?.country}
                    </p>
                </li> */}
        
                <li className="collection-item ingia-dark-grey-txt grey lighten-3">
                    {parent_company?.company_name}
                </li>   
            </ul>            
        </div>
    )
}

export default LocSummary
