
import React from 'react'
import { connect } from 'react-redux'

const CheckinSuccess = ({checkin_location, location_id}) => {
    return (
        <div className='success-page center'>
            <h4>Success!</h4>
            <i className="large material-icons success-check ingia-blue-txt">check_circle</i>
            <h5>You have successfully checked into</h5>

            <h3>{ checkin_location ? checkin_location.location_name : 'Loading...' }</h3>
            <h6>Show this to guard before entry</h6>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;

    const locations = state.firestore.ordered.locations;
    const locationsData = state.firestore.data.locations;

    const location_id = locations ? locations.filter(location => location.location_qr === id)
    .map(location => {
        return location.id
    }) : null

    const location = locationsData ? locationsData[id] : null
    console.log(locationsData[id])
    return {
        checkin_location : location,
        location_id : location_id, 
    }
}

export default connect(mapStateToProps) (CheckinSuccess)
