import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'

import './navbar.css'
import logo from '../../assets/images/logo/Ingia_Logo Design-03.svg'
import SignedInLinks from './SignedInLinks';
import MobileSignedInLinks from './MobileSignedInLinks';
import MobileSignedOutLinks from './MobileSignedOutLinks';
import SignedOutLinks from './SignedOutLinks';

import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";




class Navbar extends Component {

    componentDidMount() {
        const M = window.M;
        M.AutoInit();
    }
    render() {

        const { auth, profile } = this.props;
        const links = auth.uid ? <SignedInLinks profile={profile}/> : <SignedOutLinks />
        const homelink = auth.uid ? '/app/dashboard' : '/'

        return (
            <div className="navbar-fixed">
                <nav className="nav-wrapper grey lighten-3 z-depth-1">
                    <button className="sidenav-trigger" data-target='slide-out'>
                        <i className="material-icons">menu</i>
                    </button>

                    <Link to={ homelink } className='brand-logo'>
                        <img src={ logo } alt="logo here" />
                    </Link>
                    { links }

                    {/* 
                    <SignedInLinks />
                    <SignedOutLinks /> */}

                    <ul id="slide-out" className="sidenav sidenav-close black-text">
                        <MobileSignedInLinks />
                        <MobileSignedOutLinks />
                    </ul>
                </nav>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect( props => [
      
      { collection : 'company' },
      { collection : 'locations' },

      { collection : 'visitors' },

    //   { collection : 'company_roles' }
    //   { collection : 'company_roles', where: [["user_id", "==", props.auth.uid]] },
      { collection : 'qr_codes' },
      { collection : 'form_items' },
      { collection : 'location_items', orderBy: 'order_by' }
    //   { collection : 'visitors' }
  ]) 
) ( Navbar);



