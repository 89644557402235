
//ADD REFERAL
export const createReferral = (refer) => {
    return (dispatch, getState, { getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('referrals').add({
            ...refer, 
            created_on : new Date()
        }).then((ref) => {
            return ([
                dispatch({
                    type : 'CREATE_REFER_SUCCESS', ref
                })
            ])
        }).catch((err) => {
            dispatch({
                type: 'CREATE_REFER_ERROR', err
            })
        })
    }
}