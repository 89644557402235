
import React from 'react'
import { Link } from 'react-router-dom'

const ImageUploadSuccess =() => {
    return (
        <div className='image-upload-success center'>
            <h4>Image Upload Success</h4>
            <i className="material-icons check-mark">check_circle</i>
            <Link to='/app/dashboard' className='mt-20'> Back Home</Link>
        </div>
    )
}

export default ImageUploadSuccess
