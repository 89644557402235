import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './users.css';

const UserProfile = (props) => {

    const { profile } = props;
    return (
        <div className='user-profile z-depth-2'>
            <div className="profile-picture">

            </div>
            <div className="user-info">
                <ul>
                    <li>Name: <span className="profile-text">{ profile.first_name + ' ' + profile.last_name }</span></li>
                    <li>Phone Number: <span className="profile-text">{ '(' + profile.country_code + ') '  + profile.phone_number }</span></li>
                    <li>Email: <span className="profile-text">{ profile.email }</span> </li>
                    <li>Company: <span className="profile-text">{ profile.company_name }</span> </li>
                </ul>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        profile : state.firebase.profile
    }
}

export default compose(connect(mapStateToProps)) (UserProfile)
