
import React from 'react'
import { NavLink } from 'react-router-dom'

const MobileSignedOutLinks = () => {
    return (
        <ul className='mobile-links'>

            <li>
                <NavLink activeClassName='active-link' to='/contact' className='black-text'>
                    Contact
                </NavLink>                
            </li>
            <li>
                <NavLink activeClassName='active-link' to='/fullpage'className='black-text'>
                    Login
                </NavLink>                
            </li>
            <li>
                <NavLink to='/' className=' valign-wrapper mob-navlink-btn white-text'>
                    Register
                </NavLink>
            </li>
           
        </ul>
    )
}

export default MobileSignedOutLinks
