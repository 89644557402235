import './dashboard.css';
import { Link, Redirect } from 'react-router-dom';
import LocationList from '../locations/LocationList';
import dashboardimage from '../../../assets/images/art/merge.png';

import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import '../pages/pages.css'


const Dashboard = (props) =>{

    const [linksIcon, setLinksIcon] = useState('more_vert')

    
    useEffect(() =>{
        const M = window.M;
        M.AutoInit();

        // var Modalelem = document.querySelector('.modal');
        // var instanceModal = M.Modal.init(Modalelem);
        // instanceModal.open();

    })

    function handleDashLinks() {
        const dashLinks = document.getElementById('dashLinks');

        if(dashLinks.style.visibility !== 'visible'){
            dashLinks.style.visibility = 'visible';
            dashLinks.style.opacity = 1;
            setLinksIcon('close')
        }else{
            dashLinks.style.visibility = 'hidden';
            dashLinks.style.opacity = 0;
            setLinksIcon('more_vert')
        }
    }
    const { locations, auth, user_roles, profile } = props;
    
    if (!auth.uid) return <Redirect to='/app/signin' />
    if (!auth.emailVerified) return <Redirect to='/app/verifyemail' />
    return (
        <div className="dashboard-parent">
                <div className="dashboard-left">
                {/* <a className="modal-trigger" href="#referral_reminder">Modal</a> */}
                    <img src={dashboardimage} className='dashboard-image' alt="dashboard"/>
                    <div className="dash-links" id='dashLinks'>
                    <ul>
                        <li> 
                            <Link to='/privacy'>Privacy Policy</Link>
                        </li>
                        <li >
                            <Link to='/termsandconditions'>Terms & Conditions</Link>
                        </li>
                        <li >
                            <Link to='/registrationterms'>Registration Conditions</Link>
                        </li>
                        <li >
                            <Link to='/faq'>FAQ</Link>
                        </li>
                    </ul>  
                </div>

                <div className="launcher">
                    <i 
                    onClick={handleDashLinks}
                    className="material-icons  more-icons" 
                    // data-position="top"
                    // data-tooltip="I am a tooltip"
                    >{linksIcon}</i>
                </div>
                </div>


                <div className="dashboard-right">
                    <div className="dashboard-welcome">
                        <h4>Ingia Dashboard</h4>
                        
                        <h3>{profile.company_name ? profile.company_name : 'Loading...'}</h3>
                        <h5>This is where you manage your centers.</h5>
                        
                    </div>

                    <div className="dashboard-locations">
                        <LocationList locations={ locations } user_roles={user_roles} profile={profile} />
                        <Link to='/app/createlocation'>
                            <button className="filled-btn right mt-40" id='exp-btn'>
                                Add Location
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="modal" id="referral_reminder">
                    <div className="referral-reminder modal-content">
                        <h4>In order to keep using the free service, you need to refer to users or set up more than 9 active locations.</h4>
                        {/* <h5>Body</h5> */}
                    </div>
                    <div className="modal-footer ref-rem-foot">
                        <Link to = '/app/createlocation'>
                            <button className="modal-close filled-btn">Set up more centers</button>
                        </Link>
                        <Link to ='/app/referusers'>
                            <button className="modal-close filled-btn">Refer users</button>
                        </Link>
                    </div>
                </div>
        </div>
    )
}

const mapStateToProps = (state) =>{
    // console.log(state)
    return {
        locations: state.firestore.ordered.locations,
        auth: state.firebase.auth,
        user_roles : state.firestore.ordered.user_roles,
        profile : state.firebase.profile
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        {collection: 'locations'}
    ])
)(Dashboard)
