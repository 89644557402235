import { useEffect } from "react";
import { motion } from 'framer-motion';

const FAQ = () => {

    useEffect(() =>{
        const M = window.M;
        M.AutoInit();
    })
    const containerVariants = { 
        hidden : {
            x: '100vw'
        },
        visible : {
            x: '0vw',
            transition : { ease: 'easeInOut', duration: 1}
        },
        exit: {
            x: '-100vw',
            transition: {ease: 'easeInOut', duration: 1}
        }
    }

    return(
        <motion.div
        initial='hidden'
        animate='visible'
        exit='exit'
        variants={containerVariants}  
        className="faq-parent bg-2 ovfl-aut">
            <div className="landing-content z-depth-3">
                <div className="content-banner ingia-blue-bg mb-50">
                    Frequently Asked Questions
                </div>
                <ul className="collapsible">
                <li>
                    <div className="collapsible-header"><i className="material-icons ingia-blue-txt">folder</i>What happens to my personal data?</div>
                        <div className="collapsible-body"><span>          
                            Personal information that you enter into our web form  is saved in the form of cookies so that the next time you use the Ingia system, your information is remembered.  The company you are visiting will also have a copy of this information.  Both Ingia and the company visited are bound by our Privacy Policy and Terms and Conditions which expressly forbids sharing or sale of your private information to 3rd parties.
                        </span></div>
                    </li>
                    <li>
                        <div className="collapsible-header"><i className="material-icons ingia-blue-txt">money_off</i>Will Ingia sell my personal data?</div>
                        <div className="collapsible-body"><span>
                            No, Ingia will not sell your personal information.   Companies that use the Ingia system are also bound by our Privacy Policy and Terms and Conditions to not sell your data to 3rd parties. 
                        </span></div>
                    </li>
                    <li>
                        <div className="collapsible-header"><i className="material-icons ingia-blue-txt">security</i>Can data on my smartphone be harvested?</div>
                        <div className="collapsible-body"><span>
                            No, only App’s downloaded to your smartphone have the ability to harvest data from your smartphone.  A web form can only save the information entered into it by the user. 
                        </span></div>
                    </li>
                    <li>
                        <div className="collapsible-header"><i className="material-icons ingia-blue-txt">cloud_off</i>Do I need to download an App to use Ingia?</div>
                        <div className="collapsible-body"><span>
                            No, users only need an internet enabled smartphone to use the Ingia system.  Upon scanning the QR code, your browser will take you to our web form to complete your personal information.  You must accept cookies for the system to remember your info otherwise you will need to enter the same information each time.
                        </span></div>
                    </li>
                    <li>
                        <div className="collapsible-header"><i className="material-icons ingia-blue-txt">verified_user</i>What happens if I enter false personal information?</div>
                        <div className="collapsible-body"><span>To use the system you must agree to our Terms and Conditions.  By agreeing, you also agree to only enter valid and up to date personal information such as your email address and mobile phone number.  Entering incorrect information is an offence and not permitted. </span></div>
                    </li>
                    <li>
                        <div className="collapsible-header"><i className="material-icons ingia-blue-txt">location_on</i>Is it possible to scan a copy or photo of a QR code at a different location?</div>
                        <div className="collapsible-body"><span>No, the Ingia system is designed to be location specific.  Each QR code is registered to a specific location, the system will only work when the code is scanned at the approved location.  Any attempt to scan the code at a different location will lead to an error. </span></div>
                    </li>
                </ul>
            </div>
        </motion.div>
    )
}

export default FAQ;