
export const createLocation = (location, history) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        //making an async call to database
        const firestore = getFirestore();
        const adminId = getState().firebase.auth.uid;
        const userName = getState().firebase.profile.first_name;
        const user_roles = getState().firestore.ordered.user_roles;
        // const batch = firestore.batch();
        // const fireRef = firestore.collection('location_items')

        const parent_company = user_roles && user_roles
        .filter(user_role => user_role.level === 'company')
        .map(user_role => {
            return user_role.entity_id
        })

        firestore.collection('locations').add({
            ...location, 
            status: 'active',
            createdBy : userName,
            userId : adminId,
            createdOn: new Date(),
            parent_company : parent_company.toString()
        }).then((loc) => {
            return firestore.collection("qr_codes").add({
                location_id : loc.id
            }).then((qri) => {
                return firestore.collection('qr_codes').doc(qri.id).update({
                    location_qr: qri.id,
                    createdOn: new Date(),
                }).then(() => {
                    return firestore.collection('locations').doc(loc.id).update({
                        location_qr : qri.id,
                    })
                })
            }).then(() => { 
                return ([
                    firestore.collection('company').doc(parent_company.toString()).update({
                        number_of_locations :  firestore.FieldValue.increment(1)
                    }),
                    firestore.collection('user_roles').add({
                        user_id : adminId,
                        role : 'Admin',
                        role_id : 'FGMQ5Rp30ciEXhtk0oEj',
                        entity_id : loc.id, 
                        level : 'location'
                    }),

                    // fireRef.add({
                    //     location : loc.id,
                    //     f_id : 'full_name',
                    //     label : 'Name',
                    //     place_holder : 'Name...',
                    //     order_by : 'a',
                    //     required : true
                    // }),
                ])
            })
        })
        .then(() => {
            // history.push('/app/dashboard')
            dispatch({
                type: 'CREATE_LOCATION', location
            });
        }).catch((err) => {
            dispatch({
                type: 'CREATE_LOCATION_ERROR', err
            });
        })
    }
};



// DELETE LOCATION 
export const deleteLocation = (location) => {
    return (dispatch, getState, { getFirebase, getFirestore}) => {
        const firestore = getFirestore();


        const user_roles = getState().firestore.ordered.user_roles;
        // const batch = firestore.batch();

        const parent_company = user_roles && user_roles
        .filter(user_role => user_role.level === 'company')
        .map(user_role => {
            return user_role.entity_id
        })


        firestore.collection('locations').doc(location.loc_id).delete()
        .then((loca) => {
            return ([

                // DECREMENT NUMBER OF LOCATIONS AFTER DELETION
                firestore.collection('company').doc(parent_company.toString()).update({
                    number_of_locations : firestore.FieldValue.increment(-1)
                }),

                //DELETE QR CODE 
                firestore.collection('qr_codes').doc(location.location_qr).delete()

                //TODO: COPY DELETED LOCATION TO BIN FOLDER
            ])
        })
        .then(() => {
            dispatch({ type: 'LOCATION_DELETION_SUCCESS', location });
        }).catch((err) => {
            dispatch({ type: 'LOCATION_DELETION_ERROR', err });
        })
    }
}

//SUSPEND LOCATION 
export const suspendLocation = (location) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore() ;

        firestore.collection('locations').doc(location.loc_id).update({
            status : 'suspended'
        }).then(() => {
            dispatch({ type: 'LOCATION_SUSPEND_SUCCESS', location});
        }).catch((err) => {
            dispatch({ type : 'LOCATION_SUSPEND_ERROR', err})
        })
    }
}

// REACTIVATE LOCATION 
export const unSuspendLocation = (location) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        firestore.collection('locations').doc(location.loc_id).update({
            status: 'active'
        }).then(() => {
            dispatch({ type: 'LOCATION_ACTIVATE_SUCCESS', location });
        }).catch((err) => {
            dispatch({ type: 'LOCATION_ACTIVATE_ERROR', err })
        })
    }
}

//EDIT LOCATION ADDRESS
export const editAddress = (location) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        firestore.collection('locations').doc(location.loc_id).update({
            location : location.new_address
        })
    }
}