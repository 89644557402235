import { PuffLoader, PulseLoader } from "react-spinners";

const LoadingPage = (props) => {

    const message = props.message;
    return (
        <div className="loading-page">
            <PuffLoader size='200px' color='#1741ff'/>
            <h4 className='ingia-blue-txt'>
                { message }<PulseLoader size='4px' color='#1741ff'/>
            </h4>
        </div>
    )
}

export default LoadingPage;