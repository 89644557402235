
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LoadingPage from '../../LoadingPage';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';

import { resetPassword } from '../../../store/actions/authActions';

class ResetPassword extends Component {

    state = {
        email : '',
        authStatus : ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.state);
        this.props.resetPassword(this.state)
        this.setState({
            authStatus : 'loading'
        });

    }
    render() {

        const { authError } = this.props;

        if(authError === 'success') return <Redirect to='/app/signin' />
        console.log(authError);

        const containerVariants = { 
            hidden : {
                x: '100vw'
            },
            visible : {
                x: '0vw',
                transition : { ease: 'easeInOut', duration: 1}
            },
            exit: {
                x: '-100vw',
                transition: {ease: 'easeInOut', duration: 1}
            }
        }
        if(this.state.authStatus === '' ) {
            return (
                <motion.div 
                initial='hidden'
                animate='visible'
                exit='exit'
                variants={containerVariants} 
                className='bg-2 ovfl-aut register'>
                    <form onSubmit={this.handleSubmit} className="auth-form">
                        <h3>Recover your password</h3>
                        <h4>Enter the email used to set up account.</h4>
                        <div className="input-field mbtm">
                            <input 
                            placeholder='Type email here' 
                            id="email" 
                            type="email"
                            
                            required 
                            className="validate f-inpt" 
                            onChange={this.handleChange}/>
    
                            <label 
                            className="active" 
                            htmlFor="email">Email</label>
                        </div>
    
                        <div className ="auth-action">
                            <div className="auth-action-left">
                            <span><Link className="forgot-password" to='/app/signin'><i>Sign in instead</i></Link> </span>
                            </div>
    
                            <div className="auth-action-right">
                                <div className="input-field col s6 ">
                                    <button className="filled-btn">
                                        Reset Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </motion.div>
            )
            
        }else if (this.state.authStatus === 'loading'){
            return (
                <LoadingPage message='Resetting Password' />
            )
        }
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        authError : state.auth.authError,
        auth : state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword : (email) => dispatch(resetPassword(email))
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (ResetPassword)