

export const uploadLogo = (logo) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        // const firestore = getFirestore();

        var store = firebase.storage()

        store.child(logo.companyLogo).put(logo.companyLogo)
        .then(() => {
            dispatch({ type : 'LOGO_UPLOAD' });
        }).catch((err) =>{ 
            dispatch({ type : 'LOGO_UPLOA_FAIL', err })
        })


    }
}