import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { createCheckin } from '../../../store/actions/checkinActions';
import QRScanError from '../pages/QRScanError';


class CheckIn extends Component {



    state = {
    }

    componentDidMount(e){
        this.setState({
            location : this.props.location.location_into
        })
    }

    handleChange = (e) => {
        localStorage.setItem([e.target.id], e.target.value)
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {

        e.preventDefault();
        // let expiryDate = new Date();
        // const month = (expiryDate.getMonth() + 3) % 12;
        

        const inputArray = e.target; 
        for (var i = 0 ; i < inputArray.length ; i++){
            if(inputArray[i].type === 'text' || inputArray[i].type === 'tel'){
                console.log(inputArray[i].value)
                this.setState({
                    [inputArray[i].id] : inputArray[i].value
                })                
            }
            else{
                
            }
        }
        this.setState({
        }, () =>{
            this.props.createCheckin(this.state)
        })

        // this.props.createCheckin(this.state)
    }

    handleCheck = (e) => {
        this.setState({
            [e.target.id] : e.target.checked
        })
    }

    handleRadio = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    clearRadio = (e) => {
        const idy = (e.f_id + 'yes');
        const idn = (e.f_id + 'no');
        document.getElementById(idn).checked = false;
        document.getElementById(idy).checked = false;

        this.setState({
            [e.name] : undefined
        })
        console.log(e.name)
        console.log(this.state)
    }

    render() {

        const location_checkin = this.state.location

        const { checkInStatus, form_items, location_items } = this.props
        const checkboxes = location_items ? location_items.filter((loc => loc.type === 'checkbox' && loc.location === location_checkin)) : null
        const radio = location_items ? location_items.filter((rad => rad.type === 'radio' && rad.location === location_checkin)) : null
        console.log(radio);


        // if(!location_checkin) return <Redirect to='/app/signin' />
        if(checkInStatus === 'success') return <Redirect to={'/mobile/checkin/success/' + location_checkin} />

        if(form_items) {
            if(this.state.location) {
                return (
                    <div className='checkin-parent'>
    
                        <form onSubmit={this.handleSubmit} className="checkin-form">


                            <div className="input-field mb-40">
                                <input 
                                placeholder='First Name...'
                                type="text" 
                                id='first_name'
                                value={ localStorage.getItem('first_name') }
                                required 
                                className='validate f-inpt'
                                onChange={this.handleChange}
                                />
        
                                <label htmlFor="first_name" className="active fnt-16">
                                    First Name
                                </label>
                            </div>

                            <div className="input-field mb-40">
                                <input 
                                placeholder='Last Name...'
                                type="text" 
                                id='last_name'
                                value={ localStorage.getItem('last_name') }
                                required 
                                className='validate f-inpt'
                                onChange={this.handleChange}
                                />
        
                                <label htmlFor="last_name" className="active fnt-16">
                                    Last Name
                                </label>
                            </div>

                            <div className="input-field mb-40">
                                <input 
                                placeholder='Mobile...'
                                type="tel" 
                                minLength="8"
                                id='phone_number'
                                value={ localStorage.getItem('phone_number') }
                                required 
                                className='validate f-inpt'
                                onChange={this.handleChange}
                                />
        
                                <label htmlFor="phone_number" className="active fnt-16">
                                    Mobile Number
                                </label>
                            </div>

                            { location_items && location_items
                            .filter((sorted_items => sorted_items.location === location_checkin && sorted_items.type === 'text'))
                            .sort((a,b) => (a.order_by > b.order_by) ? 1 : -1)
                                .map(form_item => {
                                    return (
                                        <div className="input-field mb-40" key={form_item.id}>
                                            <input 
                                            placeholder={form_item.place_holder}
                                            type="text" 
                                            id={ form_item.f_id }
                                            value= {localStorage.getItem(form_item.f_id)}
                                            required = {form_item.required}
                                            className='validate f-inpt'
                                            onChange={this.handleChange}
                                            />
                    
                                            <label htmlFor="full_name" className="active fnt-16">
                                                { form_item.label }
                                            </label>
                                        </div>
                                    )
                                })
                            }
                            <hr />

                            {
                                checkboxes && checkboxes 
                                .map(checkbox => {
                                    return(
                                        <div className="checkbox-area" key={checkbox.f_id}>
                                            <label htmlFor={ checkbox.f_id }>
                                                <input type="checkbox" 
                                                name={checkbox.name} 
                                                id={checkbox.f_id}  
                                                checked =  {this.state[checkbox.f_id] ? this.state[checkbox.f_id]  : checkbox.value}
                                                onChange = {this.handleCheck}
                                                className='filled-in' />
                                                <span>{checkbox.label}</span>
                                            </label>
                                        </div>
                                    )
                                })
                            }
                            <hr />

                            {
                                radio && radio
                                .map(radio => {
                                    return(
                                        <div className="radio z-depth-1" key={radio.f_id}>
                                            <h5>{radio.label}</h5>
                                            <p>
                                                <label htmlFor={radio.f_id + 'yes'}>
                                                    <input type="radio" 
                                                    name={radio.name} 
                                                    id={radio.f_id + 'yes'}
                                                    value='yes'
                                                    onChange = {this.handleRadio}
                                                    />
                                                    <span>Yes</span>
                                                </label>
                                            </p>
                                            <div className="clear-btn right" onClick={() => this.clearRadio(radio)}>
                                                Clear
                                            </div>
                                            <p>
                                                <label htmlFor={radio.f_id + 'no'}>
                                                    <input 
                                                    type="radio" 
                                                    name={radio.name} 
                                                    id={radio.f_id + 'no'}
                                                    value='no'
                                                    onChange = {this.handleRadio}
                                                    />
                                                    <span>No</span>
                                                </label>
                                            </p>
                                        </div>
                                    )
                                })
                            }

        
                            <div className="input-field center" >
                                <button className="filled-btn z-depth-2" id='mobile-btn-width-full'>
                                    Check In
                                </button>
                            </div>
                            <div className="red-text center">
                            { checkInStatus ? <p>{checkInStatus}</p> : <p></p> }
                            </div>
                        </form>
                    </div>
                )
            }else{
                return(
                    <div className="center">
                        <QRScanError  checkType = 'Checkin Error' inOrOut = 'in'/>
                    </div>
                )
            }

        }else{
            return(
                <div className="center">
                    Loading...
                </div>
            )
        }
        
    }
}

const mapStateToProps = (state) => {

    return {
        checkInStatus : state.checkIn.checkInStatus,
        form_items : state.firestore.ordered.form_items,
        location_items : state.firestore.ordered.location_items
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createCheckin : (checkin) => dispatch(createCheckin(checkin))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn)