import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoadingPage from '../../LoadingPage';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';

import { signIn } from '../../../store/actions/authActions'
import { Redirect } from 'react-router';


class SignIn extends Component {

    state = {
        email : '',
        password : '',
        authStatus : ''
        //authstatus initial, loading, success, error
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state);
        console.log(this.state)
        this.setState({
            authStatus : 'loading'
        })
    }
    render() {

        const { auth, signinStatus, authError } = this.props;
        console.log('one', signinStatus)

        if (auth.uid) return <Redirect to='/app/dashboard' />
        const containerVariants = { 
            hidden : {
                x: '100vw'
            },
            visible : {
                x: '0vw',
                transition : { ease: 'easeInOut', duration: 1}
            },
            exit: {
                x: '-100vw',
                transition: {ease: 'easeInOut', duration: 1}
            }
        }
        if(this.state.authStatus === '' || signinStatus === 'error') {
            return (
                <motion.div 
                className='bg-2 sign-in ovfl-aut'
                variants={containerVariants} 
                initial='hidden'
                animate='visible'
                exit='exit'>
                    <form onSubmit={this.handleSubmit} className="auth-form signin-form">
                        <h3>Hello, Welcome back!</h3>
                        <h4>Fill in the fields below to log in to your dashboard.</h4>
                        <div className="input-field mbtm">
                            <input 
                            placeholder='Type email here' 
                            id="email" 
                            type="email"
                            
                            required 
                            className="validate f-inpt" 
                            onChange={this.handleChange}/>
    
                            <label 
                            className="active" 
                            htmlFor="email">Email</label>
                        </div>
    
                        <div className="input-field mt-40">
                            <input 
                            placeholder='Type password here' 
                            id="password" 
                            type="password"
                            
                            required 
                            className="validate f-inpt" 
                            onChange={this.handleChange}/>
    
                            <label 
                            className="active" 
                            htmlFor="password">Password</label>
                        </div>
                        
    
                        <div className ="auth-action">
                            <div className="auth-action-left">
                                <div className="col s6">
                                    <label className='fl'>
                                        <input type='checkbox'
                                        className='filled-in'
                                        id='remember_me'
                                        onChange={this.handleCheck}
                                        value='remember' />
                                        <span className=''>Remember me</span>
                                    </label>
                                </div>
                            </div>
    
                            <div className="auth-action-right">
                                <div className="input-field col s6 ">
                                    <button className="filled-btn">
                                        Sign In
                                    </button>
                                </div>
                                <span><Link className="forgot-password" to='/app/resetpassword'><i>Forgot Password?</i></Link> </span>
                            </div>
                        </div>
                        {
                            authError ? 
                            <div className="center red-text">
                                {authError}
                            </div> : 
                            null
                        }
                    </form>
                </motion.div>
            )        
        }else if (this.state.authStatus === 'loading'){
            return(
                <LoadingPage message='Signing in'/>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        signinStatus : state.auth.signinStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SignIn)