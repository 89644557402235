import React, { Component } from 'react';
import emailjs from 'emailjs-com'
import { PuffLoader, PulseLoader } from 'react-spinners';


class ContactForm extends Component {

    state = {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        contact_message: '',
        contact_status : ''
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.state);
        this.setState({
            contact_status : 'submitted'
        })
        emailjs.sendForm('service_gru80h8', 'ingia.co', e.target, 'user_ml7XgUAKh3VqEcJM2tOp4')
        .then((result) => {
            console.log("success");
        }, (error) => {
            console.log(error.text);
        });

        e.target.reset();
    }

    render() {
        if(this.state.contact_status === ''){
            return (
                <div className='contact-form-container'>
                    <form className="contact-form" onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="input-field col s6">
                                <input 
                                type="text" 
                                id='first_name'
                                name='first_name'
                                required
                                placeholder='Type first name here'
                                className="validate f-inpt"
                                onChange={this.handleChange}
                                />
    
                                <label 
                                className="active" 
                                htmlFor="first_name">First Name</label>
                            </div>
    
                            <div className="input-field col s6">
                                <input 
                                type="text" 
                                id='last_name'
                                name='last_name'
                                required
                                placeholder='Type last name here'
                                className="validate f-inpt"
                                onChange={this.handleChange}/>
    
                                <label 
                                className="active" 
                                htmlFor="last_name">Last Name</label>
                            </div>
                        </div>  
    
                        <div className="row">
                            <div className="input-field col s6">
                                <input 
                                type="email" 
                                id='email'
                                name='email'
                                required
                                placeholder='Type first email here'
                                className="validate f-inpt"
                                onChange={this.handleChange}/>
    
                                <label 
                                className="active" 
                                htmlFor="email">Email</label>
                            </div>
    
                            <div className="input-field col s6">
                                <input 
                                type="tel" 
                                id='phone_number'
                                name='phone_number'
                                required
                                placeholder='Type phone number here'
                                className="validate f-inpt"
                                onChange={this.handleChange}/>
    
                                <label 
                                className="active" 
                                htmlFor="phone_number">Phone Number</label>
                            </div>
                        </div>
                        <div className="row">
    
                        <div className="input-field col s12">
                                <input 
                                type="text" 
                                id='contact_message'
                                name='contact_message'
                                required
                                placeholder='Type message here'
                                className="validate f-inpt"
                                onChange={this.handleChange}/>
    
                                <label 
                                className="active" 
                                htmlFor="contact_message">Message</label>
                            </div>
                        </div>
    
                        <div className="input-field contact-btn">
                            <button className="filled-btn icon-btn icon-right">
                                Submit
                                <i className="material-icons">arrow_forward</i>
                            </button>   
                        </div>
                    </form>
                </div>
            )
        }else if (this.state.contact_status === 'submitted'){
            return(
                <div className="contact-submitted">
                    <h5 className='ingia-blue-txt'>Your message was submitted</h5>
                    <h6 className='ingia-blue-txt'>We will be getting back to you</h6>
                </div>
            )
        }else{
            return (
                <div className="loading-contact">
                    <PuffLoader size='100px' color='#1741ff'/>
                    <h5 className='ingia-blue-txt'>
                        Submitting<PulseLoader size='4px' color='#1741ff'/>
                    </h5>           
                </div>
            )
        }
    }
}

export default ContactForm
