import { Link } from "react-router-dom";
import LoadingPage from "../../LoadingPage";
import LocationSummary from "./LocationSummary";

const LocationList = ({locations, user_roles, profile}) => {

    const roles = user_roles && user_roles.map(user_role => {
        return user_role.entity_id
    })

    if(roles){
        return (
            <div className="location-list">
                {
                    locations && locations.filter(location => roles.includes(location.id))
                    .map(
                        location=>{
                            return (
                                <Link className='grey-text' to={'/app/location/' + location.id } key={location.id}>
                                    <LocationSummary location={location}  />
                                </Link>
                            )
                        }
                    )
                }
            </div>
        )
    }else {
        return(
            <LoadingPage message='Loading locations' />
        )
    }
}

 export default LocationList