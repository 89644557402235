import { Switch, Route } from 'react-router-dom';
import NavBar from '../layout/NavBar';

import LandingPages from './LandingPages';
import AppPages from './AppPages';
import MobilePages from './MobilePages';
import PlayField from '../playfield/PlayField';

import MobileNav from '../app/mobile/MobileNav';

const RootPage = () => {

    // const hideBanner = () => {
    //     let banner = document.getElementById('banner_');
    //     banner.style.height=0;
    // }

    // const location = useLocation();
    var first_part = window.location.pathname.split('/')[1];

    const navBar = first_part === 'mobile' ? <MobileNav />  : <NavBar />
    

    
    return (
        <div className='root-page'>
            {/* <div id='banner_' className="banner">
                <span></span>
                <span className="call-to-action">To continue enjoying the service, <Link to='/app/referusers' className='white-text'><u>refer 2 users</u></Link> </span>
                <span className="exit-call-to-action" onClick={hideBanner}><i className="material-icons">close</i></span>
            </div> */}
            {/* <NavBar /> */}

            { navBar }
            <Switch>
                <Route path='/app' component={ AppPages } />
                <Route path='/mobile' component={ MobilePages } />
                <Route path='/playfield' component={PlayField} />
                <Route path='/' component={ LandingPages } />
            </Switch>
        </div>
    )
}

export default RootPage;