
import React, { Component } from 'react';
import QRCode from 'react-qr-code';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import ingiaLogo from '../../../assets/images/logo/Ingia_Logo Design-03.svg';

import Pdf from "react-to-pdf";

class LocationQR extends Component {

    handlePrint = (e) => {
        
        window.print()
    }
    render() {
        const { auth, location_data, parent_company } = this.props;
        // const { auth, qr_codes, location_data, parent_company } = this.props;
        const id = this.props.match.params.id;

        const ref = React.createRef();
        
        if (!auth.uid) return <Redirect to='/app/signin' />
        if (!auth.emailVerified) return <Redirect to='/app/verifyemail' />

        return (
            <div className='bg-2 qr-parent center'>
                {/* <h4 className='when-print'>Print QR Code.</h4> */}
                <h5 className='when-print'>Print and display your QR Code.</h5>



                <div className="qr-container z-depth-2" id='qrDiv' ref={ref}>
                    <div className="location-logo">
                        {
                            parent_company ?
                                <div> 
                                    {
                                        parent_company.company_logo ? 
                                        <img src={parent_company.company_logo} alt={parent_company.company_name} />
                                        :
                                        parent_company.company_name
                                    }
                                </div>: null
                        }
                        {/* <img src={ parent_company ? parent_company.company_logo : null} alt={parent_company?.company_name + ' Logo'}/> */}
                    </div>
                    <h4>{ location_data ? location_data.location_name : 'Loading...' }</h4>
                    {/* <h4>{ parent_company ? parent_company.company_name : 'Loading...' }</h4> */}
                    {/* <p>{id}</p> */}
                    <div className="qr-area">
                        <QRCode size={150} className='qr-code' value = {'http://ingia.co/mobile/type/' + id} />
                    </div>
                    <h5>Scan QR code to check in and out.</h5>
                    <div className="ingia-brand">
                        <div className="ingia-logo">
                            <img src={ingiaLogo} alt=""/>
                        </div>
                        <div className="ingia-web">
                            <h5 className='blue-text'>www.ingia.co</h5>
                        </div>
                    </div>
                </div>

                <div className="qr-btns">
                    <button className="filled-btn when-print" onClick={this.handlePrint}>
                        Print
                    </button>

                    <Pdf targetRef={ref} filename="code-example.pdf" x={70} y={30}>
                        {({ toPdf }) => 
                            <button className="filled-btn when-print" onClick={toPdf}>
                                Download
                            </button>
                        // <button onClick={toPdf}>Generate Pdf</button>
                        
                        }
                    </Pdf>

                </div>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    const id = ownProps.match.params.id;
    const qr_codes = state.firestore.data.qr_codes;
    const location = qr_codes ? qr_codes[id] : null
    const location_id = location ? location.location_id : null

    const locations = state.firestore.data.locations;
    const location_data = locations ? locations[location_id] : null

    const parent_company = location_data ? location_data.parent_company : null
    const companies = state.firestore.data.company
    const company = companies ? companies[parent_company] : null


    return {
        auth : state.firebase.auth,
        qr_codes : state.firestore.ordered.qr_codes,
        location_data : location_data,
        parent_company : company
    }
}

export default connect(mapStateToProps) (LocationQR)
