import React, { Component } from 'react';
// import moment from 'moment';
import './visitor.css'
class VisitorFilters extends Component {
    state = {
        filter : 'none'
    }
    handleFilter = (e) => {
        var filt = e
        this.props.onSelectFilter(filt);
        
        var btnContainer = document.getElementById("filterDiv");
        var filterBtns = btnContainer.getElementsByClassName("filter-btn");

        for ( var i = 0 ; i < filterBtns.length; i++) {
            filterBtns[i].addEventListener("click", function() {
                var currentFilter = document.getElementsByClassName("filter-btn-active");
                if(currentFilter.length > 0) {
                    currentFilter[0].className = currentFilter[0].className.replace(" filter-btn-active", "");
                }
                this.className += " filter-btn-active"
            })
        }
        

        this.setState({
            filter : e
        })
        console.log(this.state)
    }


    render() {

        return (
            <div className='visitor-filter z-depth-2 valign-wrapper' id='filterDiv'>
                <span className='filter-btn' onClick={() => this.handleFilter('day')}>Daily</span><div className="splitter"></div>
                <span className='filter-btn' onClick={() => this.handleFilter('week')}>Weekly</span><div className="splitter"></div>
                <span className='filter-btn' onClick={() => this.handleFilter('month')}>Monthly</span>
                {/* <div className="splitter"></div> */}
                {/* <span className='filter-btn' onClick={() => this.handleFilter('custom')}>Custom</span> */}
            </div>
        )
    }
}

export default VisitorFilters
