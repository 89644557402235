import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { createLocation } from '../../../store/actions/locationActions';
import copyIcon from '../../../assets/images/art/copy-icon.png'
// import LoadingPage from '../../LoadingPage';

class CreateLocation extends Component {

    state = {
        location_name : '',
        additional_description : '',
        physical_address : '',
        country : '',
        coord : ''
        // create_location_status : 'init'
    }

 

    handleChange = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const api = require("@what3words/api");
        api.setOptions({ key: '3RZZC05H'});

        // api.convertToCoordinates(this.state.physical_address)
        // .then(data => console.log(data.coordinates));

        api.convertToCoordinates(this.state.physical_address)
        .then(data => this.setState({ coord :  data.coordinates}), () =>{console.log('one', this.state)});
        
        this.props.createLocation(this.state);
        this.props.history.push('/app/dashboard');

        console.log(this.state)
        // this.setState({
        //     create_location_status: 'creating'
        // }, () => {
        //     this.props.createLocation(this.state)
        // })
        
    }

    handleSelectLocation = (e) => {
        e.preventDefault();
        document.getElementById("map_overlay").style.display = "block";
    }

    handleOverlayClick = (e) => {
        e.preventDefault();
        document.getElementById("map_overlay").style.display = "none";
    }

    handlePaste = (e) => {
        e.preventDefault();
        let pasteArea = document.getElementById('physical_address');
        pasteArea.value = '';
        navigator.clipboard.readText()
            .then((text) =>{
                pasteArea.value = text;
                this.setState({
                    physical_address : text
                })
            })

    }

    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to='/app/signin' />
        if (!auth.emailVerified) return <Redirect to='/app/verifyemail' />

        // if(this.state.create_location_status === 'init'){
            return (
                <div className='bg-2 center ovfl-aut'>

                    <div className="map-overlay" id='map_overlay' onClick={this.handleOverlayClick}>
                        <iframe src="https://what3words.com/" className='what-3-words' title='google' frameBorder="0" allow="clipboard-read; clipboard-write"></iframe>
                        <div className="map-description">

                            <h3>
                                Copy and paste your what3words location into the location field using the copy icon <img src={copyIcon} style={{width: '20px'}} alt="" />.  Alternatively you can manually enter the words. 
                            </h3>
                            What3words is a global addressing system that divides the world into 3 meter squares and gives each square a unique combination of three words.   It is the easiest way to find and share exact locations.
                            <br /><br />Search for your approximate location using the search function.   Zoom in and choose a square that roughly represents the location of your check in station.  Within 100 meters is accurate enough.
                            Enter the what3words of this square into the ingia form to complete the setup of your station.
                            This location information is required in order for the system to know where each check in station is located and prevents fraudulent use of the system.
                        </div>
                    </div>


                    <form onSubmit={this.handleSubmit} className="create-location-form">
                        <h3>Set up a location</h3>
                        
                        <div className="input-field mt-40">
                            <input  
                            type="text" 
                            id='location_name'
                            required
                            placeholder='Type name here'
                            className="validate f-inpt"
                            onChange={this.handleChange}/>
    
                            <label 
                            className="active" 
                            htmlFor="location_name">Location Name</label>
                        </div>
    
                        <div className="input-field mt-40">
                            <input 
                            type="text" 
                            id='additional_description'
                            
                            placeholder='Type description here'
                            className="validate f-inpt"
                            onChange={this.handleChange}/>
    
                            <label 
                            className="active" 
                            htmlFor="additional_description">Additional Description</label>
                         </div>
    
                         <div className="input-field mt-40">
                            <input 
                            type="text" 
                            id='country'
                            required
                            placeholder='Clik to select country'
                            className="validate f-inpt"
                            onChange={this.handleChange}/>
    
                            <label 
                            className="active" 
                            htmlFor="country">Country</label>
                         </div>

                         <div className="location-address input-field mt-40">
                             <input 
                             type="text" 
                             id='physical_address'
                             required
                             className='loc-inpt'
                             placeholder='Select location' 
                             onChange={this.handleChange}/>
                             <button className="filled-btn" onClick={this.handleSelectLocation}>Select location</button>
                             {
                                 !!window.chrome ? <button className="filled-btn" onClick={this.handlePaste}>Paste</button> : null
                             }
                             <label 
                            className="active" 
                            htmlFor="country">Location</label>
                         </div>
    
    
                         <div className="input-field right">
                            <button className="filled-btn z-depth-2 mb-80">
                                Create Location
                            </button>
                        </div>
    
                    </form>
                </div>
            )
        // }else if (this.state.create_location_status === 'creating'){
        //     return (
        //         <LoadingPage message='Creating Location'/>
        //     )
        // }
    }
}

const mapStateToProps = (state) => {
    return {
        create_location_status : state.location.create_location_status,
        auth : state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createLocation : (location) => dispatch(createLocation(location))
    }
}


export default  connect (mapStateToProps, mapDispatchToProps)(CreateLocation)
