import authReducer from "./authReducer";
import locationReducer from "./locationReducer";

import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from 'react-redux-firebase';
import questionReducer from "./questionReducer";
import checkinReducer from "./checkinReducer";
import referReducer from "./referReducer";





const rootReducer = combineReducers({
    auth: authReducer,
    location : locationReducer,
    question : questionReducer,
    firestore : firestoreReducer,
    checkIn : checkinReducer,
    refer : referReducer,
    firebase : firebaseReducer
})

export default rootReducer; 

