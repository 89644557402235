
import React from 'react'

const QRScanError = ({checkType, inOrOut}) => {
    return (
        <div className='error-scanning center'>

            <h4>Error!</h4>
            <i className="large material-icons error-check ingia-blue-txt">cancel</i>
            <h5>There was a problem checking you { inOrOut }</h5>
            <h6>Please start up your camera and scan the QR Code again. </h6>
        </div>
    )
}

export default QRScanError
