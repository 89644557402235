
const ContactContent = () => {


    return (
        <div className="left-side-content">

            <div className="header-one">
                <h3>Contact us.</h3> 
            </div>
            <div className="header-two">
                <h4>Have questions about our services?</h4>
            </div>
            <div className="header-two">
                <h4>Fill in the contact form to get in touch, we'll get back to you momentarily.</h4>          
            </div>
        </div>
    )
}

export default ContactContent;