import React from 'react';
import '../app/auth/auth.css';
import '../app/setup/setup.css';

import { AnimatePresence } from 'framer-motion';

import { Switch, Route } from 'react-router-dom';
import SignIn from '../app/auth/SignIn';
import ResetPassword from '../app/auth/ResetPassword';
import Register from '../app/auth/Register';
import Dashboard from '../app/dashboard/Dashboard';
import LocationData from '../app/locations/LocationData';
import CreateLocation from '../app/locations/CreateLocation';
import LocationQR from '../app/locations/LocationQR';
import Question from '../app/questions/Question';
import VerifyAccount from '../app/pages/VerifyAccount';
import CompanyLogo from '../app/setup/CompanyLogo';
import ImageUploadSuccess from '../app/pages/ImageUploadSuccess';
import ReportFrequency from '../app/setup/ReportFrequency';
import Reports from '../app/reports/Reports';
import ReferUser from '../app/referrals/ReferUser';
import AccountSuspended from '../app/pages/AccountSuspended';
import Maps from '../app/maps/Maps'
import ReferralRegister from '../app/auth/ReferralRegister';

// import { Link } from 'react-router-dom';
import UserProfile from '../app/users/UserProfile';
import EditMap from '../app/maps/EditMap';

function AppPages() {

    // const hideBanner = () => {
    //     let banner = document.getElementById('banner_');
    //     banner.style.height=0;
    // }
    return (
        <div className='app-page'>
            {/* <div id='banner_' className="banner">
                <span></span>
                <span className="call-to-action">To continue enjoying the service, <Link to='/app/referusers' className='white-text'><u>refer 2 users</u></Link> </span>
                <span className="exit-call-to-action" onClick={hideBanner}><i className="material-icons">close</i></span>
            </div> */}
            <AnimatePresence exitBeforeEnter>
                <Switch>
                    <Route path='/app/signin' component={ SignIn } />
                    <Route path='/app/resetpassword' component= { ResetPassword } />
                    <Route path='/app/register' component = { Register } />
                    <Route path='/app/referralregister/:id' component = {ReferralRegister} />
                    <Route path='/app/companylogo/:id' component={CompanyLogo} />
                    <Route path='/app/imageuploadsuccess' component={ImageUploadSuccess} />
                    <Route path='/app/reportfrequency' component={ReportFrequency} />
                    <Route path='/app/dashboard' component = { Dashboard} />
                    <Route path='/app/createlocation' component= { CreateLocation } />
                    <Route path='/app/location/:id' component= { LocationData } />
                    <Route path='/app/qrcode/:id' component={LocationQR} />
                    <Route path='/app/question/:id' component={Question} />

                    <Route path='/app/userprofile' component={UserProfile} />
                    <Route path='/app/editmap/:id' component={EditMap} />

                    <Route path='/app/reports/:id' component={Reports} />
                    <Route path='/app/referusers' component={ReferUser} />
                    <Route path='/app/accountsuspended' component={AccountSuspended} />                    
                    <Route path='/app/verifyemail' component={VerifyAccount} />
                    <Route path='/app/maps' component={Maps} />
                </Switch>
            </AnimatePresence>
        </div>
    )
}

export default AppPages
